import { createSelector } from "reselect";
import { OFFER_TYPES, PAYMENT_METHODS, PAYMENT_OPTIONS } from "app/constants";
import {
	getBookingOfferType,
	getQuoteTotalWithDelta,
	isBookingEligibleForDeposit,
} from "app/pages/Booking/bookingSelectors";
import { calculateEcheancierCasino } from "app/utils/echeancierUtils";
import uniq from "lodash/uniq";
import get from "lodash/get";
import values from "lodash/values";
import { shouldApplyCasinoSansFrais } from "app/reducers/partnerSelector";

const getPaymentTypes = state => get(state, "payment.paymentTypes");
const getBookingPaymentType = state => get(state, "booking.paymentType");
const getBookingPaymentMode = state => get(state, "booking.paymentMode");
const getnformationStandardFormHiddenOnHotelOnly = state =>
	get(state, "documents.isInformationStandardFormHiddenOnHotelOnly");
const getInformationStandardForm = state => get(state, "documents.informationStandardForm");

export const checkIfPaymentCasino4xDisponible = createSelector(
	[getPaymentTypes],
	(paymentTypes = []) => {
		const paymentType4Fois = paymentTypes.find(paymentType => {
			return paymentType.paymentType === PAYMENT_METHODS.CASINO && paymentType.terms === 4;
		});

		return paymentType4Fois ? true : false;
	}
);

export const calculatePartialPaymentAmount = createSelector(
	[getQuoteTotalWithDelta, getPaymentTypes, isBookingEligibleForDeposit],
	(total = 0, paymentTypes = [], isBookingEligibleForDeposit = false) => {
		const partialPayment = paymentTypes.find(paymentType => {
			return paymentType.terms === PAYMENT_OPTIONS["2x"];
		});

		if (partialPayment) {
			const payNowAmount = isBookingEligibleForDeposit
				? Math.ceil((total * partialPayment.percentagePayNow) / 100)
				: (total * partialPayment.percentagePayNow) / 100;

			const payLaterAmount = total - payNowAmount;

			return {
				dueDate: partialPayment.dueDate,
				percentagePayNow: partialPayment.percentagePayNow,
				payLaterAmount,
				payNowAmount,
			};
		}
		return {};
	}
);

/**
 * Voici la règle de calcul concernant l’échéancier :
 * Taux appliqués
 * De 60              à 1000.00            : 2.39%
 * De 1001              à 1500.00            : 2.19%
 * De 1500.01         à 2000.00            : 1.97%
 * De 2000.01         à 2500.00            : 1.70%
 * De 2500            à 10000.00           : 1.48%
 * La règle est la suivante : les échéances 2 à 4 doivent être égales.
 * La première échéance permet de récupérer les centimes restants.
 *
 * Exemple :
 *
 * Panier à 130.47 <sch:TotalAmount>13047</sch:TotalAmount> en entrée du Score
 *
 * Taux appliqué 2.35%
 *
 * Panier avec frais 133.536045 <TotalAmount>13354</TotalAmount> en sortie du Score
 *
 * Echéancier 4X : 133.536045/4 = 33.38401125
 *
 * Echéance 2 : 33.38
 * Echéance 3 : 33.38
 * Echéance 4 : 33.38
 * Echéance 1 : 133.536045 – (3x33.38) = 33.396045 => 33.40
 */
export const getEcheancierCasino = createSelector(
	[getQuoteTotalWithDelta, shouldApplyCasinoSansFrais],
	(total = 0, applyCasinoSansFrais) => {
		return calculateEcheancierCasino(total, applyCasinoSansFrais);
	}
);

export const getSupportedPaymentTypes = createSelector(
	[getPaymentTypes],
	(paymentTypes = []) => {
		return paymentTypes.filter(paymentType => {
			return values(PAYMENT_METHODS).includes(paymentType.paymentType);
		});
	}
);

/**
 * retourne la liste de modes de paiements (1, 2, 4) triés par ordre décroissant.
 * Un mode de paiement est le nombre possibles de mensualités
 */
export const getPaymentModes = createSelector(
	[getSupportedPaymentTypes],
	(paymentTypes = []) => {
		const paymentModes = paymentTypes.map(paymentType => {
			return paymentType.terms;
		});

		return uniq(paymentModes.sort());
	}
);

/**
 * retourne le mode de payment actuel qui peut être :
 * - soit celui que l'utilisateur a sélectionné
 * - soit le plus petit mode de paiement disponible
 * - soit 1 sit la totalité
 */
export const getCurrentPaymentMode = createSelector(
	[getBookingPaymentMode],
	selectedPaymentMode => {
		// if (selectedPaymentMode) {
		// 	return selectedPaymentMode;
		// } else if (paymentModes[0]) {
		// 	return paymentModes[0];
		// }
		// return PAYMENT_OPTIONS["1x"];

		// TODO A virer apres l'ab test deposit
		return selectedPaymentMode;
	}
);

export const getAllowedPaymentTypes = createSelector(
	[getCurrentPaymentMode, getSupportedPaymentTypes],
	(selectedPaymentMode, paymentTypes = []) => {
		if (!selectedPaymentMode) {
			return [];
		}
		return paymentTypes.filter(paymentType => paymentType.terms === selectedPaymentMode);
	}
);

/**
 * Par défaut, on choisit ce que l'utilisateur a selectionné en tant que type de paiement.
 * Si l'utilisateur n'a pas sélectionné de moyen de paiement, alors on prend la valeur du premier type de paiement disponible
 **/
export const getCurrentPaymentType = createSelector(
	[getBookingPaymentType, getAllowedPaymentTypes],
	(selectedPaymentType, allowedPaymentTypes = []) => {
		if (
			selectedPaymentType &&
			values(
				allowedPaymentTypes.map(allowedPaymentType => allowedPaymentType.paymentType)
			).includes(selectedPaymentType)
		) {
			return selectedPaymentType;
		}

		return get(allowedPaymentTypes, "[0].paymentType");
	}
);

const getInsurancesItems = state => state.payment.insurances;
const getPassengersNumber = state =>
	state.booking.adults + state.booking.children + state.booking.infants;
const getTripDuration = state => state.booking.duration.value;

export const getInsurancesSplittedPrice = createSelector(
	[getInsurancesItems, getPassengersNumber, getTripDuration],
	(insurances = [], passengerNumber = 1, tripDuration = 1) => {
		return {
			...insurances,
			insurancesItem:
				(insurances.insurancesItem &&
					insurances.insurancesItem.map(insurance => ({
						...insurance,
						splittedPrice: insurance.price / (passengerNumber * tripDuration),
					}))) ||
				[],
		};
	}
);

export const shouldShowInformationStandardForm = createSelector(
	[getnformationStandardFormHiddenOnHotelOnly, getInformationStandardForm, getBookingOfferType],
	(isInformationStandardFormHiddenOnHotelOnly = false, informationStandardForm, offerType) => {
		if (!informationStandardForm) {
			return false;
		}

		return !(
			isInformationStandardFormHiddenOnHotelOnly &&
			offerType === OFFER_TYPES.ACCOMODATION_ONLY
		);
	}
);
