import HomeHeader from "@brand/Home/HomeHeader";
import { connect } from "react-redux";

const mapStateToProps = state => {
	return {
		brand: state.brand.code,
	};
};

export default connect(mapStateToProps)(HomeHeader);
