import {
	FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_FAILURE,
	FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_REQUEST,
	FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_SUCCESS,
} from "app/actionTypes";
import isEmpty from "lodash/isEmpty";

export const fetchABTestsActiveCampaignsSuccess = activeCampaigns => ({
	type: FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_SUCCESS,
	activeCampaigns,
});

export const fetchABTestsActiveCampaignsFailure = () => ({
	type: FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_FAILURE,
});

export const fetchABTestsActiveCampaignsRequest = () => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			/* set abTests status to LOADING */
			dispatch({ type: FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_REQUEST });

			// on commente pour les tests unitaires
			// if (process.env.NODE_ENV !== "production") {
			// 	// Pour forcer une campagne et une variation pour tester en dev local
			// 	setInterval(() => {
			// 		resolve([
			// 			{
			// 				campaignID: AB_TESTS.DEPOSIT.CAMPAIGN_ID,
			// 				variationID: AB_TESTS.DEPOSIT.VARIATION_ID_DEPOSIT,
			// 			},
			// 		]);
			// 	}, 2000);
			// } else if (window.ABTasty) {
			if (window.ABTasty) {
				let count = 0;
				const intervalID = setInterval(() => {
					const testsOnPage = window.ABTasty.getTestsOnPage();
					if (!isEmpty(testsOnPage)) {
						window.clearInterval(intervalID);

						const pairs = Object.keys(testsOnPage).map(campaignID => ({
							campaignID,
							variationID: String(testsOnPage[campaignID].variationID),
						}));

						resolve(pairs);
					}

					if (++count === 10) {
						window.clearInterval(intervalID);
						reject(undefined);
					}
				}, 100);
			} else {
				reject("ABTasty n'est pas chargée");
			}
		})
			.then(activeCampaigns => dispatch(fetchABTestsActiveCampaignsSuccess(activeCampaigns)))
			.catch(() => dispatch(fetchABTestsActiveCampaignsFailure()));
	};
};
