import React from "react";
import { FormattedMessage } from "react-intl";
import "./TechnicalErrorSSO.scss";
import { getOauthIndentityProviderUrl } from "app/utils/auth";
import { parseQueries } from "app/utils/routerUtils";
import { withRouter } from "react-router-dom";

const TechnicalErrorSSO = ({ location }) => {
	const query = parseQueries(location.search);
	const { redirectUri } = query;

	return (
		<div className="technical-error">
			<div className="technical-error__intro">
				<FormattedMessage id="technical.sso.error.message" />

				<div className="technical-error__cta">
					<a
						className="relative-link relative-link--primary"
						href={getOauthIndentityProviderUrl(redirectUri)}
						data-cy={"go-to-login-button"}
					>
						<FormattedMessage id="technical.sso.cta.label" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default withRouter(TechnicalErrorSSO);
