import {
	FETCH_PROFILE_REQUEST,
	FETCH_PROFILE_SUCCESS,
	FETCH_PROFILE_FAILURE,
	LOGOUT,
	SAVE_NEWSLETTER_PREFERENCES_SUCCESS,
	SAVE_PROFILE_REQUEST,
	SAVE_PROFILE_SUCCESS,
} from "app/actionTypes";

import { DELETE_PROFILE_SUCCESS } from "app/pages/Account/Profile/profileActionTypes";
import { PROFILE_CODE_STATUS } from "app/constants";

const defaultProfile = {
	status: undefined,
	name: {},
	address: {},
	passport: {},
	newsletterPreference: {},
	deleted: false,
};

export default function profileReducer(profileState = defaultProfile, action) {
	switch (action.type) {
		case DELETE_PROFILE_SUCCESS:
			return {
				...profileState,
				deleted: true,
			};
		case FETCH_PROFILE_REQUEST:
			return {
				...defaultProfile,
				status: PROFILE_CODE_STATUS.LOADING,
			};
		case FETCH_PROFILE_FAILURE:
			return {
				...defaultProfile,
				status: PROFILE_CODE_STATUS.FAILURE,
			};
		case FETCH_PROFILE_SUCCESS: {
			const newProfile = {
				status: PROFILE_CODE_STATUS.SUCCESS,
				...action.profile,
			};

			return {
				...profileState,
				...newProfile,
			};
		}
		case SAVE_PROFILE_REQUEST:
			return Object.assign({}, profileState, { status: undefined });
		case SAVE_PROFILE_SUCCESS: {
			const profile = action.res.data;
			profile.status = PROFILE_CODE_STATUS.SUCCESS;
			return Object.assign({}, profileState, profile);
		}
		case SAVE_NEWSLETTER_PREFERENCES_SUCCESS: {
			const newsletterPreference = action.res.data.newsletterPreference;
			return Object.assign({}, profileState, {
				status: PROFILE_CODE_STATUS.SUCCESS,
				newsletterPreference: newsletterPreference,
			});
		}
		case LOGOUT:
			return defaultProfile;
		default:
			return profileState;
	}
}
