import { FETCH_FIRST_PRODUCTS_SUCCESS, FETCH_PRODUCTS_SUCCESS } from "app/actionTypes";
import { getDuration } from "app/utils/utils";
import { compact } from "lodash/array";

const initialState = [];

export default (products = initialState, action) => {
	switch (action.type) {
		case FETCH_FIRST_PRODUCTS_SUCCESS: {
			const { products: dataProducts } = action.res.data;
			let productList = [];

			if (dataProducts && action.spotlight) {
				const spotlightProduct = dataProducts.find(
					product => product.uri === action.spotlight
				);
				// add spotlight product to four first products
				productList = [...dataProducts.slice(0, 4), spotlightProduct];
			} else {
				productList = dataProducts && dataProducts.slice(0, 4);
			}
			const now = new Date();
			let newProductList = [];

			if (productList && productList.length > 0) {
				newProductList = compact(productList).map(product => {
					if (product.endAt) {
						product.expiration = getDuration(now.getTime(), product.endAt);
					}

					if (product.startAt) {
						product.availabilityDate = getDuration(now.getTime(), product.startAt);
					}

					return product;
				});
			}
			return newProductList;
		}
		case FETCH_PRODUCTS_SUCCESS: {
			const now = new Date();
			const productList = action.res.data.products;
			let newProductList = [];

			if (productList && productList.length > 0) {
				newProductList = productList.map(product => {
					if (product.endAt) {
						product.expiration = getDuration(now.getTime(), product.endAt);
					}

					if (product.startAt) {
						product.availabilityDate = getDuration(now.getTime(), product.startAt);
					}

					return product;
				});
			}
			return newProductList;
		}
		default:
			return products;
	}
};
