import axios from "axios/index";
import env from "app/utils/env";
import {
	BURN_CREDITS,
	CLEAR_BURNED_CREDITS,
	FETCH_CREDITS,
	FETCH_SPONSORSHIP,
	GET_SPONSOR_ID,
	SET_SPONSOR_ID,
	SPONSOR_FRIENDS,
} from "app/actionTypes";
import { getCredentials } from "app/utils/auth";

export const fetchSponsorship = () => {
	const { token } = getCredentials();
	return {
		type: FETCH_SPONSORSHIP,
		promise: axios.get(env("SPONSORSHIP_API_URL") + "/sponsorship", {
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		}),
	};
};

export const getSponsorId = () => {
	const { token } = getCredentials();
	return {
		type: GET_SPONSOR_ID,
		promise: axios.get(env("SPONSORSHIP_API_URL") + "/getSponsorId", {
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		}),
	};
};

export const setSponsorId = sponsorId => {
	return {
		type: SET_SPONSOR_ID,
		sponsorId,
	};
};

export const sponsorFriends = payload => {
	const { token } = getCredentials();
	return {
		type: SPONSOR_FRIENDS,
		promise: axios.post(env("SPONSORSHIP_API_URL") + "/inviteByEmail", payload, {
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		}),
	};
};

export const fetchCredits = connectivitySessionCode => {
	const { token } = getCredentials();
	return {
		type: FETCH_CREDITS,
		promise: axios.post(
			env("SPONSORSHIP_API_URL") + "/usableCredit",
			{
				connectivitySessionCode,
			},
			{
				headers: {
					Authorization: token,
					"Content-type": "application/json",
				},
			}
		),
	};
};

export const burnCredits = ({ credits, creditIds }) => {
	return {
		type: BURN_CREDITS,
		credits,
		creditIds,
	};
};

export const clearBurnedCredits = () => {
	return {
		type: CLEAR_BURNED_CREDITS,
	};
};
