import { createSelector } from "reselect";
import { STRATEGY } from "app/constants";

const getStrategy = state => state.partner.strategy;
export const getBrand = state => state.brand.code;
export const isWhiteLabel = createSelector(
	[getStrategy],
	strategy => {
		return (
			strategy === STRATEGY.SIGNUP_FIRST ||
			strategy === STRATEGY.NAVIGATION_FIRST ||
			strategy === STRATEGY.PRODUCT_FIRST
		);
	}
);
