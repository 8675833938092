import { SET_FACEBOOK_READY, SET_GOOGLE_API_READY } from "app/actionTypes";

const initialPayment = {
	facebookSDKReady: false,
	isGoogleApiReady: false,
};

export default (externalStatus = initialPayment, action) => {
	switch (action.type) {
		case SET_FACEBOOK_READY:
			return Object.assign({}, externalStatus, { facebookSDKReady: true });
		case SET_GOOGLE_API_READY:
			return Object.assign({}, externalStatus, { isGoogleApiReady: true });
		default:
			return externalStatus;
	}
};
