import { createSelector } from "reselect";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { getFallbackRedirectUrl } from "src/@brand/appConstants";
import { BRANDS } from "app/constants";

const getResolution = state => state.resolution;
const getIOQuoteExitUrl = state => state.io.quoteExitUrl;
const getFicheProduitUri = state => state.ficheProduit.uri;
const getBrand = state => state.brand.code;

export const getQuoteExitUri = createSelector(
	[getResolution, getIOQuoteExitUrl, getFicheProduitUri, getBrand],
	(resolution, ioQuoteExitUrl, ficheProduitUri, brand) => {
		const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
		let productUri = ioQuoteExitUrl || (ficheProduitUri ? `/${ficheProduitUri}` : undefined);
		const quoteExitUrl = isMobile && brand !== BRANDS.VP ? "/booking/search" : productUri;

		return quoteExitUrl || getFallbackRedirectUrl();
	}
);
