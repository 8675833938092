import { createSelector } from "reselect";

const getLandingPages = state => state.landingPagesAuth.landingPages;
const getActiveLandingPageCode = state => state.landingPagesAuth.activeLandingPageCode;

export const getCurrentLandingPage = createSelector(
	[getLandingPages, getActiveLandingPageCode],
	(landingPages = [], activeLandingPageCode) =>
		landingPages.find(landingPage => landingPage.code === activeLandingPageCode)
);
