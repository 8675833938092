import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getPartnerRedirectionLink, getSocialNetworksLinks } from "app/reducers/partnerSelector";
import { checkIfPaymentCasino4xDisponible } from "app/pages/Booking/Payment/paymentSelector";
import { getContextCallCenter } from "app/pages/Booking/bookingSelectors";
import { getCurrentLandingPage } from "app/pages/LandingPage/Auth/LandingAuthSelector";
import Footer from "@brand/Footer/default/Footer";

const mapStateToProps = (state, ownProps) => {
	const contextCallCenter = getContextCallCenter({})(state);
	const { location } = ownProps;
	const isPaymentCasino4xDisponible = checkIfPaymentCasino4xDisponible(state);
	const isListingPage = location.pathname.includes("/listing");
	const isMerchPage = location.pathname.includes("/merch");
	const isQuotePage = location.pathname.includes("/quote");
	const isPaymentPage = location.pathname.includes("/payment");
	const isHomePage = location.pathname.includes("/home");
	const showCasinoNote = (isQuotePage || isPaymentPage) && isPaymentCasino4xDisponible;

	return {
		socialNetworksLinks: getSocialNetworksLinks(state),
		partnerRedirectionLink: getPartnerRedirectionLink(state),
		shop: state.shop,
		landingPageAuth: getCurrentLandingPage(state),
		helpContactUrl: contextCallCenter.helpContactUrl,
		cgvDocumentName: state.documents.cgv,
		legalesDocumentName: state.documents.legales,
		confidentialiteDocumentName: state.documents.confidentialite,
		partnerCode: state.partner.code,
		showCasinoNote,
		isHomePage,
		isListingPage,
		isMerchPage,
		resolution: state.resolution,
	};
};

const FooterContainer = withRouter(connect(mapStateToProps)(Footer));
export default FooterContainer;
