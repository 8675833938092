import React, { Component } from "react";
import { connect } from "react-redux";
import Reassurance from "app/pages/Footer/Reassurance/Reassurance";

class ReassuranceContainer extends Component {
	render() {
		return <Reassurance {...this.props} />;
	}
}

const mapStateToProps = state => {
	const countryCode = state.shop.slice(3, 5);
	const partnerCode = state.partner.code;

	return {
		showPayment4x: countryCode === "FR",
		showPaypal: countryCode !== "CH" && partnerCode !== "PSVP",
	};
};

export default connect(mapStateToProps)(ReassuranceContainer);
