const initialState = {
	code: undefined,
	partnerDataNames: [],
	partnerTokensNames: [],
	defaultPartnerCode: undefined,
	shops: {},
};

export default (brand = initialState) => {
	return brand;
};
