import PropTypes from "prop-types";
import React from "react";
import get from "lodash/get";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { FormattedMessage } from "react-intl";
import { PriceType } from "app/pages/.shared/Deal/PriceType.jsx";
import "./ScrollSpyPrice.scss";

const ScrollSpyPrice = ({ ficheProduit, isRental }) => {
	return (
		<div className="price-button">
			<div className="price-button__price">
				<PriceType
					type={get(ficheProduit, "fromPriceType.type")}
					value={get(ficheProduit, "fromPriceType.value")}
					includeOfferWithFlight={get(ficheProduit, "includeOfferWithFlight")}
					duration={get(ficheProduit, "fromPriceType.numberOfNights")}
					publicPrice={get(ficheProduit, "fromPriceType.publicPrice")}
					isRental={isRental}
				/>
			</div>
			<div className="price-button__action">
				<RelativeLink
					design="primary"
					to={{ pathname: `/${ficheProduit.uri}`, hash: "#search-engine" }}
					className="price-button__button"
				>
					<FormattedMessage id="product.book.now" />
				</RelativeLink>
			</div>
		</div>
	);
};

ScrollSpyPrice.propTypes = {
	ficheProduit: PropTypes.object,
	isRental: PropTypes.bool,
};

ScrollSpyPrice.defaultProps = {
	ficheProduit: {},
};

export default React.memo(ScrollSpyPrice);
