import { getStore } from "app/configureStore";
import env from "app/utils/env";
import axios from "axios";
import { FETCH_PARTNER_DATA } from "app/reducers/partnerActionTypes";

export const getPartnerData = () => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_PARTNER_DATA,
		promise: axios.get(`${endpoint}/resources/${shop}/shop.json`),
	};
};
