import { createSelector } from "reselect";
import omit from "lodash/omit";
import { BRANDS } from "app/constants";

const getBrandCode = state => state.brand.code;
const getBrandShops = state => state.brand.shops;

export const getCurrentShop = state => state.shop;

export const getSwitchableShops = createSelector(
	[getBrandCode, getBrandShops, getCurrentShop],
	(brandCode, shops = {}, currentShop) => {
		// on veut masquer de-DE pour la collection air france. A supprimer quand AFIT et AFES seront disponibles
		let switchableShops = shops;

		if (brandCode === BRANDS.AF && currentShop !== "es-ES" && currentShop !== "it-IT") {
			switchableShops = omit(shops, ["it-IT", "es-ES"]);
		}

		const shopCodes = Object.keys(switchableShops);

		return shopCodes.map(shopCode => {
			return {
				code: shopCode,
				label: switchableShops[shopCode].label,
			};
		});
	}
);
