import React from "react";
import "./SimpleHeader.scss";
import { sendTagOnContactCall } from "app/utils/analytics";
import PropTypes from "prop-types";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import { brandPropTypes } from "app/utils/propTypes";
import { BRANDS } from "app/constants";
import IconPhone from "app/pages/.shared/static/icons/IconPhoneBordered";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";

class SimpleHeader extends React.PureComponent {
	render() {
		const {
			previousUrl,
			dialPhone,
			hideBackButton = false,
			brand,
			operatorEmail,
			isInApp,
		} = this.props;

		return isInApp ? (
			false
		) : (
			<div className="simple-header">
				<div className="simple-header__back">
					{!hideBackButton && previousUrl && (
						<BackButton
							to={{
								pathname: previousUrl,
							}}
						/>
					)}
				</div>
				<div className="simple-header__title">
					<LogoBrandContainer
						isWhite={
							brand === BRANDS.EK ||
							brand === BRANDS.HP ||
							brand === BRANDS.SE ||
							brand === BRANDS.TZ
						}
					/>
				</div>
				{operatorEmail && (
					<div className="simple-header__operator">
						<strong>Agent :</strong> {operatorEmail}
					</div>
				)}
				<div className="simple-header__phone">
					{dialPhone && (
						<a
							className="simple-header__phone-button"
							onClick={() => {
								sendTagOnContactCall();
							}}
							href={`tel:${dialPhone}`}
						>
							<IconPhone height={30} width={30} />
						</a>
					)}
				</div>
			</div>
		);
	}
}

SimpleHeader.propTypes = {
	brand: brandPropTypes,
	dialPhone: PropTypes.string,
	previousUrl: PropTypes.string,
	hideBackButton: PropTypes.bool,
	operatorEmail: PropTypes.string,
	isInApp: PropTypes.bool,
};

export default SimpleHeader;
