import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./header.scss";
import Hamburger from "app/pages/Header/Hamburger/Hamburger";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { FormattedMessage } from "react-intl";
import { sendDataOnClickCollectionPermanente, sendTagOnContactCall } from "app/utils/analytics";
import { BackTo } from "app/pages/FicheProduit/components/BackTo/BackTo";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";
import { getFallbackRedirectUrl } from "src/@brand/appConstants";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import IconBack from "app/pages/.shared/static/icons/IconBack";
import IconMyBookings from "app/pages/.shared/static/icons/IconMyBookings";
import { isProductPage } from "app/utils/urlDataExtractor";

const Header = ({
	dialPhone,
	toggleAsideMenu,
	isAsideMenuOpened,
	isAsideProductFilterOpened,
	hideAsideProductFilters,
	resetAllProductsFilter,
	merchandisingCode,
	referrer,
	match,
}) => {
	const isListingPage = match.url === "/listing";
	const isMerchPage = match.url === "/merch";
	const isFicheProduitPage = isProductPage(match.path);

	let HamburgerElement = <Hamburger onToogle={toggleAsideMenu} isActive={isAsideMenuOpened} />;

	if (isAsideProductFilterOpened) {
		HamburgerElement = (
			<Hamburger onToogle={hideAsideProductFilters} isActive={isAsideProductFilterOpened} />
		);
	}
	if (isFicheProduitPage) {
		HamburgerElement = <BackTo merchandisingCode={merchandisingCode} referrer={referrer} />;
	}

	const backToOptions = isMerchPage
		? {}
		: {
				merchandisingCode,
				referrer,
		  };

	return (
		<React.Fragment>
			<div className="header">
				<div className="header__container">
					<div className="header__hamburger">{HamburgerElement}</div>
					<div style={{ flex: "1 1 0%" }}>
						<RelativeLink
							to={{ pathname: "/listing" }}
							className="header__logo"
							onClick={resetAllProductsFilter}
						>
							<LogoBrandContainer />
						</RelativeLink>
					</div>
					<div className="header__phone">
						{dialPhone ? (
							<a
								className="header__phone-button"
								onClick={sendTagOnContactCall}
								href={`tel:${dialPhone}`}
							>
								<i className="icon icon--phone" />
							</a>
						) : (
							false
						)}
					</div>
					<div className="header__my-bookings">
						<RelativeLink to={{ pathname: "/mybookings" }}>
							<IconMyBookings height={25} width={25} />
							<FormattedMessage id="menu.item.my.bookings" />
						</RelativeLink>
					</div>
				</div>
			</div>
			{isMerchPage && (
				<div className="header__back header__back--mobile">
					<BackButton to={{ pathname: getFallbackRedirectUrl() }} />
				</div>
			)}
			<div className="header header__nav">
				<div className="header__container">
					<div className="header__back">
						{isListingPage ? (
							<a className="relative-link" href="https://www.laredoute.fr/">
								<IconBack width={20} height={20} style={{ marginRight: "10px" }} />
								La Redoute
							</a>
						) : (
							<BackTo
								{...backToOptions}
								labelBack="general.back"
								labelFallback="general.back"
							/>
						)}
					</div>

					<div className="header__menu">
						<RelativeLink
							to={{ pathname: "/listing" }}
							className={classNames({
								"relative-link": true,
								"header__menu-item": true,
								"header__menu-item--active": isListingPage,
							})}
						>
							<FormattedMessage id="menu.flash.sales.label" />
						</RelativeLink>
						<RelativeLink
							to={{ pathname: "/listing", hash: "#collection-permanente" }}
							className={classNames({
								"relative-link": true,
								"header__menu-item": true,
								"header__menu-item--active": false,
							})}
							onClick={sendDataOnClickCollectionPermanente}
						>
							<FormattedMessage id="menu.collection.permanente.label" />
						</RelativeLink>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

Header.propTypes = {
	dialPhone: PropTypes.string,
	toggleAsideMenu: PropTypes.func,
	hideAsideProductFilters: PropTypes.func,
	isAsideMenuOpened: PropTypes.bool,
	isAsideProductFilterOpened: PropTypes.bool,
	route: PropTypes.object,
	resetAllProductsFilter: PropTypes.func,
	merchandisingCode: PropTypes.string,
	referrer: PropTypes.string,
	match: PropTypes.object,
};

Header.defaultProps = {
	match: {},
};

export default React.memo(Header);
