import { createSelector } from "reselect";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import sortedUniq from "lodash/sortedUniq";
import partition from "lodash/partition";
import compact from "lodash/compact";
import sortBy from "lodash/sortBy";
import take from "lodash/take";
import pull from "lodash/pull";
import flow from "lodash/flow";
import pick from "lodash/pick";
import filter from "lodash/filter";
import omitBy from "lodash/omitBy";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import max from "lodash/max";
import min from "lodash/min";
import forOwn from "lodash/forOwn";
import { getDurationAsMilliseconds } from "app/utils/utils";
import {
	CABINS,
	OFFER_CONTRACT_TYPES,
	OFFER_TYPES,
	PASSENGER_TYPE,
	QUOTATION_MAX_DEFAULT_ACTIVITIES,
} from "app/constants";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import pickBy from "lodash/pickBy";
import get from "lodash/get";

const getAccommodations = state => state.quotation.accommodations;
const getBoardsDescription = state => state.quotation.boards;
const getRoomDescriptions = state => state.quotation.roomDescriptions;
const sortFlightsBy = state => state.quotation.sortFlightsBy;
const flightsPageSize = state => state.quotation.flightsPageSize;
const accommodationsPageSize = state => state.quotation.accommodationsPageSize;
const filterFlightsBy = state => state.quotation.filterFlightsBy;
const getBookingAccommodation = state => state.booking.accommodation;
const getFlights = state => state.quotation.flights;
const isChildAgeRequired = state => state.ficheProduit.isChildAgeRequired;
const getProductId = state => state.ficheProduit.id;
const getBooking = state => state.booking;
const getFlight = state => state.booking.flight;
const getPartner = state => state.partner;
const getAuth = state => state.auth;
const getShowAllActivities = state => state.quotation.showAllActivities;
const getQuotationActivities = state => state.quotation.activities;

const limitFlights = (pageSize, flights) => {
	return take(flights, pageSize);
};

const sortFlights = (sortPredicate, flights) => {
	return sortBy(flights, sortPredicate);
};

const filterFlightsByTotalOutboundDuration = (duration, flights) => {
	const filteredFlights = filter(flights, flight => {
		const durationMilliseconds = getDurationAsMilliseconds(
			get(flight, "outbound.totalDuration")
		);
		return durationMilliseconds <= duration;
	});
	return filteredFlights;
};

const filterFlightsByTotalInboundDuration = (duration, flights) => {
	const filteredFlights = filter(flights, flight => {
		const durationMilliseconds = getDurationAsMilliseconds(
			get(flight, "inbound.totalDuration")
		);
		return durationMilliseconds <= duration;
	});
	return filteredFlights;
};

const filterFlightsByLuggages = (isIncluded, flights) => {
	const filteredFlights = filter(flights, flight => {
		return (
			flight.flightOptions &&
			flight.flightOptions.flightBagageOption &&
			flight.flightOptions.flightBagageOption.included === isIncluded
		);
	});
	return filteredFlights;
};

const filterFlightsByDepartureAirport = (airport, flights) => {
	const filteredFlights = filter(flights, flight => {
		return get(flight, "outbound.legs[0].from.airport") === airport;
	});
	return filteredFlights;
};

const filterFlightsByArrivalAirport = (airport, flights) => {
	const filteredFlights = filter(flights, flight => {
		const lastLegIndex = get(flight, "outbound.legs.length") - 1;
		return get(flight, `outbound.legs[${lastLegIndex}].to.airport`) === airport;
	});
	return filteredFlights;
};

const filterFlightsByUpgradePrice = (price, flights) => {
	const filteredFlights = filter(flights, flight => {
		return flight.upgradePrice <= price || flight.upgradePrice === undefined;
	});
	return filteredFlights;
};

const filterFlightsByCabin = (cabin, flights) => {
	const filteredFlights = filter(flights, flight => {
		return flight.outbound.cabin === cabin;
	});
	return filteredFlights;
};

const filterFlightsByAirline = (airline, flights) => {
	const filteredFlights = filter(flights, flight => {
		return flight.outbound.airline.name === airline;
	});
	return filteredFlights;
};

const filterFlightsByInboundTakeOffTime = (time, flights) => {
	const filteredFlights = filter(flights, flight => {
		const durationMilliseconds = getDurationAsMilliseconds(
			get(flight, "inbound.legs[0].from.takeOffTime")
		);
		return time.min <= durationMilliseconds && durationMilliseconds <= time.max;
	});
	return filteredFlights;
};

const filterFlightsByOutboundTakeOffTime = (time, flights) => {
	const filteredFlights = filter(flights, flight => {
		const durationMilliseconds = getDurationAsMilliseconds(
			get(flight, "outbound.legs[0].from.takeOffTime")
		);
		return time.min <= durationMilliseconds && durationMilliseconds <= time.max;
	});
	return filteredFlights;
};

const filterFlightsByStopCount = (stop, flights) => {
	const filteredFlights = filter(flights, flight => {
		// Si vol direct, alors on retourne les vols dont l'aller et le retour sont des vols directs
		if (stop === 0) {
			return (
				get(flight, "outbound.legs.length") - 1 === stop &&
				flight.inbound &&
				flight.inbound.legs.length - 1 === stop
			);
		}
		return get(flight, "outbound.legs.length") - 1 <= stop;
	});
	return filteredFlights;
};

export const getFilteredFlights = createSelector(
	[getFlights, filterFlightsBy],
	(flights = [], filterPredicates) => {
		const filters = [];

		if (filterPredicates.outboundDuration !== undefined) {
			filters.push(
				filterFlightsByTotalOutboundDuration.bind(this, filterPredicates.outboundDuration)
			);
		}

		if (filterPredicates.inboundDuration !== undefined) {
			filters.push(
				filterFlightsByTotalInboundDuration.bind(this, filterPredicates.inboundDuration)
			);
		}

		if (filterPredicates.luggage !== undefined) {
			filters.push(filterFlightsByLuggages.bind(this, filterPredicates.luggage));
		}

		if (filterPredicates.stop !== undefined) {
			filters.push(filterFlightsByStopCount.bind(this, filterPredicates.stop));
		}

		if (
			filterPredicates.inboundTakeOffTime &&
			filterPredicates.inboundTakeOffTime.min !== undefined &&
			filterPredicates.inboundTakeOffTime.max !== undefined
		) {
			filters.push(
				filterFlightsByInboundTakeOffTime.bind(this, filterPredicates.inboundTakeOffTime)
			);
		}

		if (
			filterPredicates.outboundTakeOffTime &&
			filterPredicates.outboundTakeOffTime.min !== undefined &&
			filterPredicates.outboundTakeOffTime.max !== undefined
		) {
			filters.push(
				filterFlightsByOutboundTakeOffTime.bind(this, filterPredicates.outboundTakeOffTime)
			);
		}

		if (filterPredicates.departureAirport !== undefined) {
			filters.push(
				filterFlightsByDepartureAirport.bind(this, filterPredicates.departureAirport)
			);
		}

		if (filterPredicates.arrivalAirport !== undefined) {
			filters.push(filterFlightsByArrivalAirport.bind(this, filterPredicates.arrivalAirport));
		}

		if (filterPredicates.airline !== undefined) {
			filters.push(filterFlightsByAirline.bind(this, filterPredicates.airline));
		}

		if (filterPredicates.upgradePrice !== undefined) {
			filters.push(filterFlightsByUpgradePrice.bind(this, filterPredicates.upgradePrice));
		}

		if (filterPredicates.cabin !== undefined) {
			filters.push(filterFlightsByCabin.bind(this, filterPredicates.cabin));
		}

		if (filters.length === 0) {
			return flights;
		}

		return flow(...filters)(flights);
	}
);

export const getDepartureAirportFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const airports = flights.map(flight => {
			return get(pick(flight, "outbound"), "outbound.legs[0].from.airport");
		});
		return sortedUniq(airports.sort());
	}
);

export const getArrivalAirportFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const airports = flights.map(flight => {
			const lastLegIndex = get(flight, "outbound.legs.length") - 1;
			return get(pick(flight, "outbound"), `outbound.legs[${lastLegIndex}].to.airport`);
		});
		return sortedUniq(airports.sort());
	}
);

export const getCabinFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const cabins = flights.map(flight => {
			return pick(flight, "outbound").outbound.cabin;
		});
		return uniq(cabins);
	}
);

export const getMinUpgradePricesGroupByCabin = createSelector(
	[getFlights],
	(flights = []) => {
		const upgradePriceByCabin = {};

		let flightsByCabin = groupBy(flights, flight => flight.outbound.cabin);

		forOwn(flightsByCabin, (flights, cabin) => {
			const upgradePrices = flights.map(flight => {
				return pick(flight, "upgradePrice").upgradePrice;
			});

			upgradePriceByCabin[cabin] = min(upgradePrices);
		});

		return upgradePriceByCabin;
	}
);

export const getMinUpgradePricesGroupByCabinPerPerson = createSelector(
	[getMinUpgradePricesGroupByCabin, getBooking],
	(upgradePriceByCabin = {}, booking = []) => {
		/* infant doesn't count */
		const passengersCount = booking.adults + booking.children;
		const upgradePriceByCabinPerPerson = { ...upgradePriceByCabin };

		for (const [key, value] of Object.entries(upgradePriceByCabin)) {
			upgradePriceByCabinPerPerson[key] = Math.ceil(value / passengersCount);
		}

		return upgradePriceByCabinPerPerson;
	}
);

export const getUpgradePriceFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const upgradePrices = flights.map(flight => {
			return pick(flight, "upgradePrice").upgradePrice;
		});

		const maxUpgradePrice = max(upgradePrices);

		return maxUpgradePrice > 0 ? maxUpgradePrice : 0;
	}
);

export const getAirlinesFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const airlines = flights.map(flight => {
			return pick(flight, "outbound").outbound.airline.name;
		});
		return sortedUniq(airlines.sort());
	}
);

export const getInboundDurationsFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const durations = flights.map(flight => {
			return get(pick(flight, "inbound"), "inbound.totalDuration");
		});

		return {
			max: getDurationAsMilliseconds(max(durations)),
			min: getDurationAsMilliseconds(min(durations)),
		};
	}
);

export const getOutboundDurationsFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const durations = flights.map(flight => {
			return get(pick(flight, "outbound"), "outbound.totalDuration");
		});

		return {
			max: getDurationAsMilliseconds(max(durations)),
			min: getDurationAsMilliseconds(min(durations)),
		};
	}
);

export const getInboundTakeOffTimeFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const durations = flights.map(flight => {
			return get(pick(flight, "inbound"), "inbound.legs[0].from.takeOffTime");
		});

		return {
			max: getDurationAsMilliseconds(max(durations)),
			min: getDurationAsMilliseconds(min(durations)),
		};
	}
);

export const getOutboundTakeOffTimeFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const durations = flights.map(flight => {
			return get(pick(flight, "outbound"), "outbound.legs[0].from.takeOffTime");
		});

		return {
			max: getDurationAsMilliseconds(max(durations)),
			min: getDurationAsMilliseconds(min(durations)),
		};
	}
);

export const getStopCountFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const stops = flights.map(flight => {
			if (get(flight, "outbound.legs.length") - 1 === 0) {
				if (get(flight, "inbound.legs.length") - 1 === 0) {
					return 0;
				}
				return undefined;
			}
			return get(flight, "outbound.legs.length") - 1;
		});

		return uniq(pull(stops, undefined));
	}
);

export const getFiltersCount = createSelector(
	[
		filterFlightsBy,
		getInboundDurationsFilterValues,
		getOutboundDurationsFilterValues,
		getInboundTakeOffTimeFilterValues,
		getOutboundTakeOffTimeFilterValues,
		getUpgradePriceFilterValues,
	],
	(
		filters = {},
		inboundDurationFilterValues = {},
		outboundDurationFilterValues = {},
		inboundTakeOffTimeFilterValues = {},
		outboundTakeOffTimeFilterValues = {},
		upgradePriceFilterValues = {}
	) => {
		return Object.keys(
			omitBy(filters, (filterValue, filterKey) => {
				return (
					filterValue === undefined ||
					filterKey === "cabin" ||
					isEqual(filterValue, inboundDurationFilterValues.max) ||
					isEqual(filterValue, outboundDurationFilterValues.max) ||
					isEqual(filterValue, inboundTakeOffTimeFilterValues) ||
					isEqual(filterValue, outboundTakeOffTimeFilterValues) ||
					filterValue === upgradePriceFilterValues
				);
			})
		).length;
	}
);

export const getFormattedAccommodations = createSelector(
	[getAccommodations, getRoomDescriptions],
	(accommodations = [], roomDescriptions = []) =>
		orderBy(
			accommodations.map(accommodation => {
				const accommodationItems = accommodation.items
					? accommodation.items.map(item => {
							// eslint-disable-next-line max-nested-callbacks
							const roomDescription = find(roomDescriptions, roomDescription => {
								return (
									item.accommodationItemCode ===
									roomDescription.accommodationItemCode
								);
							});
							return Object.assign({}, item, roomDescription);
					  })
					: [];

				const roomsCode = accommodationItems.map(item => item.accommodationItemCode);

				const uniquRoomCodes = uniq(roomsCode);

				const roomDetails = uniquRoomCodes.map(accommodationItemCode => {
					// eslint-disable-next-line max-nested-callbacks
					return find(roomDescriptions, roomDescription => {
						return roomDescription.accommodationItemCode === accommodationItemCode; // eslint-disable-line max-nested-callbacks
					});
				});

				accommodation.details = roomDetails ? roomDetails : [];
				accommodation.items = accommodationItems;

				return accommodation;
			}),
			accommodation => accommodation.items.length,
			["asc"]
		)
);

export const limitAccommodations = (pageSize, accommodations) => take(accommodations, pageSize);

export const getNotIncludedAccommodations = createSelector(
	[getFormattedAccommodations],
	(accommodations = []) => {
		return filter(accommodations, accommodation => accommodation.included !== true) || [];
	}
);

export const getExtraAccommodations = createSelector(
	[getNotIncludedAccommodations, accommodationsPageSize],
	(accommodations = [], accommodationsPageSize) => {
		return limitAccommodations(accommodationsPageSize, accommodations);
	}
);

export const getIncludedAccommodation = createSelector(
	[getFormattedAccommodations],
	(accommodations = []) => {
		return find(accommodations, accommodation => accommodation.included === true);
	}
);

export const getSelectedAccommodation = createSelector(
	[getIncludedAccommodation, getBookingAccommodation],
	(includedAccommodation, userSelectedAccommodation = {}) => {
		if (userSelectedAccommodation.code) {
			return userSelectedAccommodation;
		} else if (includedAccommodation) {
			return includedAccommodation;
		}
		return {};
	}
);

export const getBoardsDescriptionOfSelectedAccommodation = createSelector(
	[getSelectedAccommodation, getBoardsDescription],
	(selectedAccommodation = {}, boardsDescription = []) => {
		const boardsOfSelectedAccommodations = selectedAccommodation.codes
			? uniq(selectedAccommodation.codes.map(code => code.boardCode))
			: [];
		const selectedBoardsDescription = boardsOfSelectedAccommodations.map(board => {
			return find(boardsDescription, boardDescription => {
				return boardDescription.boardCode === board;
			});
		});
		return compact(selectedBoardsDescription);
	}
);

export const getIncludedAndExtraBoardsOfSelectedAccommodation = createSelector(
	[getSelectedAccommodation, getBoardsDescription],
	(selectedAccommodation = {}, boardsDescription = []) => {
		const uniqCodesOfSelectedAccommodations = selectedAccommodation.codes
			? uniqBy(selectedAccommodation.codes, code => code.boardCode)
			: [];

		/* Inserting { ..., included, upgradePrice } to descriptions */
		const selectedBoardsDescription = uniqCodesOfSelectedAccommodations.map(code => {
			const matchingBoard = find(boardsDescription, boardDescription => {
				return boardDescription.boardCode === code.boardCode;
			});
			const { included, upgradePrice } = code;
			return { ...matchingBoard, included, upgradePrice };
		});

		const partitionedBoardsDescription = partition(
			compact(selectedBoardsDescription),
			boardDescription => boardDescription.included === true
		);
		return {
			includedBoards: partitionedBoardsDescription[0],
			extraBoards: partitionedBoardsDescription[1],
		};
	}
);

export const getIncludedFlight = createSelector(
	[getFlights],
	(flights = []) => {
		return find(flights, flight => flight.included === true);
	}
);

export const getTransfersOfSelectedFlight = createSelector(
	[getFlight],
	(flight = {}) => {
		return flight.transfers || [];
	}
);

export const getIncludedTransfer = createSelector(
	[getTransfersOfSelectedFlight],
	(transfers = []) => {
		return find(transfers, transfer => transfer.included === true);
	}
);

export const buildQuotationPayload = createSelector(
	[getBooking, getPartner, getAuth, isChildAgeRequired, getProductId],
	(booking = [], partner = {}, auth = {}, isChildAgeRequired, productId) => {
		let payload = undefined;

		const childrenPassengers = booking.passengers.filter(
			passenger => passenger.type === PASSENGER_TYPE.CHILD
		);
		const infantsPassengers = booking.passengers.filter(
			passenger => passenger.type === PASSENGER_TYPE.INFANT
		);

		if (!isEmpty(booking.offer)) {
			payload = {
				uuid: auth.uuid,
				partnerCode: partner.code,
				duration: booking.duration.value,
				departureDate: booking.departureDate,
				price: booking.price,
				config: booking.config,
				productId,
			};

			if (booking.offer.contractType !== OFFER_CONTRACT_TYPES.RENTAL) {
				payload.adults = booking.adults;
				payload.children = booking.children;
				payload.infants = booking.infants;
				payload.childrenBirthdates =
					childrenPassengers.length > 0 && isChildAgeRequired
						? childrenPassengers.map(passenger => passenger.birthdate)
						: [];
				payload.infantsBirthdates =
					infantsPassengers.length > 0 && isChildAgeRequired
						? infantsPassengers.map(passenger => passenger.birthdate)
						: [];
			}

			if (booking.offer.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION) {
				payload.departureCityCode = booking.departureCity.code;
			}
			payload = pickBy(payload, v => v !== "");
		}
		return payload;
	}
);

export const checkShowFlightFilters = createSelector(
	[getFlights],
	(flights = []) => {
		const flighsPerCabin = groupBy(flights, flight => {
			return flight.outbound.cabin;
		});

		let shouldHideFlightFilters = false;

		Object.keys(flighsPerCabin).forEach(flight => {
			if (flighsPerCabin[flight].length > 5) {
				shouldHideFlightFilters = true;
			}
		});

		return shouldHideFlightFilters;
	}
);

export const getTaggedFlights = createSelector(
	[getFlights],
	(flights = []) => {
		const taggedFlights = flights.filter(flight => flight.tag);
		return taggedFlights;
	}
);

export const getVisibleFlights = createSelector(
	[
		getFilteredFlights,
		getTaggedFlights,
		flightsPageSize,
		sortFlightsBy,
		getFiltersCount,
		filterFlightsBy,
	],
	(
		flights = [],
		taggedFlights = [],
		flightsPageSize,
		sortFlightsBy,
		filtersCount,
		filterFlightsBy = {}
	) => {
		return filtersCount === 0 && filterFlightsBy.cabin === CABINS.ECONOMY
			? limitFlights(
					flightsPageSize,
					uniqBy([...taggedFlights, ...sortFlights(sortFlightsBy, flights)], "code")
			  )
			: flow(
					sortFlights.bind(this, sortFlightsBy),
					limitFlights.bind(this, flightsPageSize)
			  )(flights);
	}
);

export const getActivities = createSelector(
	[getQuotationActivities, getShowAllActivities],
	(activities, showAllActivities) => {
		if (showAllActivities) {
			return activities;
		}

		return activities.slice(0, QUOTATION_MAX_DEFAULT_ACTIVITIES);
	}
);

export const shouldNotShowAllActivities = createSelector(
	[getQuotationActivities, getShowAllActivities],
	(activities, showAllActivities) => {
		return !showAllActivities && activities.length > QUOTATION_MAX_DEFAULT_ACTIVITIES;
	}
);
