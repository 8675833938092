import { getStore } from "app/configureStore";
import {
	mapFicheProduitStoreToTag,
	mapPageStoreToTag,
	mapPaymentStoreToTag,
	mapPaymentTypesStoreToTag,
	mapPrePackageStoreToTag,
	mapProductToTag,
	mapQuoteStoreToTag,
	mapUserStoreToTag,
} from "app/utils/analytics-store-mapper";
import axios from "axios";
import Cookies from "js-cookie";
import { getUTCDateForAnalytics } from "app/utils/utils";
import get from "lodash/get";

const dataLayer = typeof window !== "undefined" ? window.dataLayer : [];

const sendDataToAnalyticsEndPoint = data => {
	// @see https://console.aws.amazon.com/s3/buckets/datalayer.perfectstay.io pour voir les infos envoyés (refrech toutes les 10 minutes)

	const state = getStore().getState();
	data.user = mapUserStoreToTag(state);
	data._ga = Cookies.get("_ga");

	const newData = {
		...data,
		originalEvent: data.event,
	};

	axios
		.post("https://x6pc9ierpi.execute-api.eu-west-1.amazonaws.com/live", JSON.stringify(data), {
			headers: { "Content-type": "text/plain" },
		})
		.catch(() => {
			newData.event = "send_tag_big_query_error";
			if (dataLayer) {
				dataLayer.push(newData);
			}
		});
};

const sendTag = data => {
	const state = getStore().getState();

	const tagData = {
		...data,
		brand: state.brand.code,
		shop: state.shop,
		hotjarSessionId: get(window, "_hjSettings.hjid", ""), // id de session de recording hotjar
	};

	if (dataLayer) {
		dataLayer.push(tagData);
	}

	if (process.env.NODE_ENV === "production") {
		sendDataToAnalyticsEndPoint(tagData);
	}
};

export const sendTagOnUnmatchedResolution = (clientResolution, serverResolution) => {
	sendTag({
		event: "unmatched_server_client_resolution",
		clientResolution,
		serverResolution,
	});
};

/**
 * Ce tag doit être envoyé au premier chargement de la page et à chaque changement d'url côté front.
 * En effet, GA est paramétré pour être téléchargé dans le navigateur à chaque virtualPageLoading. Sans GA chargé
 * au démarrage de l'appli, il n'y a pas d'analytics envoyés.
 */
export const virtualPageLoading = ({ pageTitle, shop, productId, landingPageTopic }) => {
	let state = getStore().getState();

	// si le productId peut être récupéré depuis la page listing, alors on met à jour le state
	if (productId) {
		state = { ...state, ficheProduit: { ...state.ficheProduit, id: productId } };
	}

	let pageTitleToSend = pageTitle;

	if (landingPageTopic) {
		pageTitleToSend = pageTitle + landingPageTopic;
	}

	sendTag({
		event: "virtualPageLoading",
		user: mapUserStoreToTag(state),
		page: mapPageStoreToTag(pageTitleToSend, shop, state),
		shop: state.shop,
	});
};

export const sendTagOnEmailSignin = () => {
	const state = getStore().getState();
	sendTag({
		event: "email_signin",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnEmailSigninError = ({ status, error }) => {
	const state = getStore().getState();
	sendTag({
		event: "email_signin_error",
		user: mapUserStoreToTag(state),
		status,
		error,
	});
};

export const sendTagOnEmailSignup = () => {
	const state = Object.assign({}, getStore().getState(), { type: "member" }); // variable poussée une seule fois après un signup réussi
	sendTag({
		event: "email_signup",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnEmailSignupError = ({ status, error }) => {
	const state = getStore().getState();
	sendTag({
		event: "email_signup_error",
		user: mapUserStoreToTag(state),
		status,
		error,
	});
};

export const sendTagOnFacebookSignin = () => {
	const state = getStore().getState();
	sendTag({
		event: "facebook_signin",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnFacebookSignup = () => {
	const state = getStore().getState();
	sendTag({
		event: "facebook_signup",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnFacebookError = error => {
	const state = getStore().getState();
	sendTag({
		event: "facebook_error",
		user: mapUserStoreToTag(state),
		error,
	});
};

export const sendTagOnListingLoad = (products = []) => {
	sendTag({
		event: "product_list",
		list: "PRODUCT_LIST",
		products: products
			.map(mapProductToTag)
			.map((product, position) => ({ ...product, position })),
	});
};

export const sendTagOnMerchandisingViewedOnListing = (code, label, position) => {
	sendTag({
		event: "merch_view",
		label,
		code,
		position: String(position),
		merchCode: code,
	});
};

export const sendTagOnMerchandisingButtonClick = (merchandisingCode, position, indexCarousel) => {
	sendTag({
		event: `merchandising_click`,
		code: merchandisingCode,
		position: String(position),
		indexCarousel: String(indexCarousel),
		merchCode: merchandisingCode,
	});
};

export const sendTagOnMerchandisingSwitch = merchandisingCode => {
	sendTag({
		event: `merchandising_switch`,
		code: merchandisingCode,
		merchCode: merchandisingCode,
	});
};

export const sendTagOnMerchandisingListingLoad = (
	code,
	label,
	position,
	indexCarousel,
	products = []
) => {
	sendTag({
		event: "merchandising_product_list",
		code,
		label,
		position: String(position),
		indexCarousel: String(indexCarousel),
		products: products
			.map(mapProductToTag)
			.map((product, position) => ({ ...product, position })),
		merchCode: code,
	});
};

export const pushTagOnProductViewButtonClick = (product, position) => {
	const state = getStore().getState();

	sendTag({
		event: "view_offer",
		product: mapProductToTag(product, position),
		merchCode: state.merchandising.current.code,
	});
};

export const pushTagOnGridViewActive = () => {
	const state = getStore().getState();

	sendTag({
		event: "grid_view",
		merchCode: state.merchandising.current.code,
	});
};

export const pushTagOnListViewActive = () => {
	const state = getStore().getState();

	sendTag({
		event: "list_view",
		merchCode: state.merchandising.current.code,
	});
};

export const sendTagOnProductPageLoad = () => {
	const state = getStore().getState();

	sendTag({
		event: "product_page",
		product: mapFicheProduitStoreToTag(getStore().getState().ficheProduit),
		merchCode: state.merchandising.current.code,
	});
};

export const sendTagOnJeuConcoursPageLoad = label => {
	sendTag({
		event: "jeu_concours_page",
		label,
	});
};

export const sendTagOnLandingProductPageLoad = () => {
	sendTag({
		event: "landing-product_page",
		product: mapFicheProduitStoreToTag(getStore().getState().ficheProduit),
	});
};

export const pushTagOnOfferTypeSelect = offerType => {
	const state = getStore().getState();
	sendTag({
		event: "select_offer",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		offerType,
		merchCode: state.merchandising.current.code,
	});
};

export const pushTagOnDepartureCitySelect = departureCityLabel => {
	const state = getStore().getState();
	sendTag({
		event: "select_flight",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		departureCity: departureCityLabel,
		merchCode: state.merchandising.current.code,
	});
};

export const pushTagOnAlternativeDepartureCitySelect = departureCityLabel => {
	const state = getStore().getState();
	sendTag({
		event: "select_alternative_departure_city",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		departureCity: departureCityLabel,
		merchCode: state.merchandising.current.code,
	});
};

export const pushTagOnPassengerSelect = () => {
	const state = getStore().getState();
	sendTag({
		event: "select_guests",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: state.merchandising.current.code,
	});
};

export const pushTagOnRentalAccommodationSelect = () => {
	const state = getStore().getState();
	sendTag({
		event: "select_rental_accommodation",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: state.merchandising.current.code,
	});
};

export const pushTagOnDepartureDateSelect = (departureDate, checkoutDate) => {
	const state = getStore().getState();
	sendTag({
		event: "select_date",
		prePackage: mapPrePackageStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		departureDate: getUTCDateForAnalytics(departureDate),
		checkoutDate: getUTCDateForAnalytics(checkoutDate),
		merchCode: state.merchandising.current.code,
	});
};

export const pushTagOnDurationSelect = duration => {
	const state = getStore().getState();
	sendTag({
		event: "select_duration",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		duration: String(duration),
		merchCode: state.merchandising.current.code,
	});
};

export const pushTagOnSearchEngineSubmit = () => {
	const state = getStore().getState();
	sendTag({
		event: "book_now",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		merchCode: state.merchandising.current.code,
	});
};

export const sendTagOnVoucherCodeSubmission = () => {
	const state = getStore().getState();

	sendTag({
		event: "add_voucherCode",
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: state.merchandising.current.code,
	});
};

export const sendTagOnQuotationPageLoad = quotationReloadUrl => {
	const state = getStore().getState();
	sendTag({
		event: "quotation_page",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: {
			...mapQuoteStoreToTag(state),

			quotationReloadUrl,
		},
		merchCode: state.merchandising.current.code,
	});
};

export const sendTagOnQuotationResult = ({ status, data = {} }) => {
	const state = getStore().getState();
	sendTag({
		event: "quotationPage_result",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: {
			httpStatusCode: String(status),
			status: data.status,
			flightCount: data.flights ? String(data.flights.length) : "",
			accommodationCount:
				data.accommodations && data.accommodations.accommodationItems
					? String(data.accommodations.accommodationItems.length)
					: "",
			boardCount:
				data.accommodations && data.accommodations.boards
					? String(data.accommodations.boards.length)
					: "",
			transferCount: data.transfers ? String(data.transfers.length) : "",
			quotePrice: data.quotationItems
				? String(data.quotationItems[0].price + data.quotationItems[1].price)
				: "",
		},
		merchCode: state.merchandising.current.code,
	});
};

export const sendTagOnQuotationFail = httpStatus => {
	const state = getStore().getState();
	sendTag({
		event: "quotationPage_fail",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		httpStatus: String(httpStatus),
		quote: mapQuoteStoreToTag(state),
		merchCode: state.merchandising.current.code,
	});
};

export const pushTagQuotationSubmit = () => {
	const state = getStore().getState();
	sendTag({
		event: "proceed_payment",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: state.merchandising.current.code,
	});
};

export const sendTagOnFlightOptionsFail = () => {
	const state = getStore().getState();
	sendTag({
		event: "flightOptions_fail",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: state.merchandising.current.code,
	});
};

export const sendTagOnPrebookFail = () => {
	const state = getStore().getState();
	sendTag({
		event: "prebook_fail",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: state.merchandising.current.code,
	});
};

export const sendTagOnPaymentInputBlur = inputName => {
	const state = getStore().getState();

	sendTag({
		event: "payment_input_blur",
		inputName,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: state.merchandising.current.code,
	});
};

export const sendTagOnPaymentPageLoad = () => {
	const state = getStore().getState();
	const {
		ficheProduit,
		merchandising: { current },
		payment: { paymentTypes },
	} = state;

	sendTag({
		event: "payment_page",
		product: mapFicheProduitStoreToTag(ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: current.code,
		paymentTypes: mapPaymentTypesStoreToTag(paymentTypes),
	});
};

export const pushTagOnPaymentSubmit = paymentData => {
	sendTag(buildPaymentTag(paymentData));
};

export const buildPaymentTag = paymentData => {
	const state = getStore().getState();
	return {
		event: "complete_payment",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		payment: mapPaymentStoreToTag(paymentData),
		merchCode: get(state, "merchandising.current.code"),
	};
};

export const sendTagOnPaymentModeSelect = paymentMode => {
	const state = getStore().getState();
	sendTag({
		event: "payment_mode_select",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		paymentModeUpgrade: String(paymentMode),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnPaymentTypeSelect = paymentType => {
	const state = getStore().getState();
	sendTag({
		event: "payment_type_select",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		paymentTypeUpgrade: String(paymentType),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnConfirmationPageLoad = paymentData => {
	const state = Object.assign({}, getStore().getState(), { status: "client" }); // variable poussée une seule fois après un signup réussi
	sendTag({
		event: "payment_confirmation",
		user: mapUserStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		payment: mapPaymentStoreToTag(paymentData),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnShowMap = () => {
	const state = getStore().getState();

	sendTag({
		event: "show_map",
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnInsuranceSelect = ({ code, name, price }) => {
	const state = getStore().getState();

	sendTag({
		event: "insurance",
		quote: mapQuoteStoreToTag(state),
		insurance: {
			code: `${code}`,
			name: `${name}`,
			price: `${price}`,
		},
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnError = errorKey => {
	const state = getStore().getState();

	sendTag({
		event: "error",
		error: errorKey,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnApiCallbackError = (apiUrl, error = {}) => {
	const state = getStore().getState();

	sendTag({
		event: "api_callback_error",
		user: mapUserStoreToTag(state),
		apiUrl,
		errorMessage: error.message || "",
		errorStack: error.stack || "",
		quote: mapQuoteStoreToTag(state),
		prePackage: mapPrePackageStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
	});
};

export const sendTagOnTokenRenewByPassed = () => {
	sendTag({
		event: "token_renew_bypassed",
	});
};

export const sendTagOnTokenRenewSuccess = () => {
	sendTag({
		event: "token_renew_success",
	});
};

export const sendTagOnTokenRenewError = error => {
	sendTag({
		event: "token_renew_error",
		error: error || "",
	});
};

export const sendTagOnTokenDeleteError = error => {
	sendTag({
		event: "token_delete_error",
		error: error || "",
	});
};

/*
 * @params status voir https://developers.google.com/maps/documentation/javascript/places#place_details_responses
 * @params type  getPredictions | getDetails
 */
export const sendTagOnGoogleAutoCompleteError = (type, status) => {
	sendTag({
		event: "google_autocomplete_error",
		type,
		status,
	});
};

export const sendTagOnGoogleAutocompleteSelect = address => {
	sendTag({
		event: "google_autocomplete_address_selected",
		address,
	});
};

export const sendTagOnContactCall = () => {
	const state = getStore().getState();

	sendTag({
		event: "contact_call",
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnSubscriptionUpdate = ({ frequency, remarketing }) => {
	sendTag({
		event: "subscription_update",
		frequency,
		remarketing: `${remarketing}`,
	});
};

export const sendTagOnPriceChanged = (newPrice, oldPrice) => {
	const state = getStore().getState();
	sendTag({
		event: "price_changed",
		newPrice,
		oldPrice,
		user: mapUserStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnProductsFilterOpen = filter => {
	sendTag({
		event: `listingPage_filter_by_${filter}_open`,
	});
};

export const sendTagOnProductsFilterRemove = filter => {
	sendTag({
		event: `listingPage_filter_by_${filter}_remove`,
	});
};

export const sendTagOnProductsFilter = (filter, value) => {
	sendTag({
		event: `listingPage_filter_by_${filter}`,
		value,
	});
};

export const sendTagOnMerchandisingProductsFilterRemove = value => {
	const state = getStore().getState();

	sendTag({
		event: `merchandisingPage_filter_remove`,
		value,
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnMerchandisingProductsFilter = value => {
	const state = getStore().getState();

	sendTag({
		event: `merchandisingPage_filter`,
		value,
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnFlightFilter = (filter, value) => {
	const state = getStore().getState();

	sendTag({
		event: `flight_filter_${filter}`,
		value,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnFlightResetFilter = () => {
	const state = getStore().getState();

	sendTag({
		event: `flight_filter_reset`,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnFlightShowMore = (
	visibleFlightCount,
	filteredFlightCount,
	allFlightCount
) => {
	const state = getStore().getState();

	sendTag({
		event: `flight_show_more`,
		visibleFlightCount,
		filteredFlightCount,
		allFlightCount,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnAccommodationsShowMore = () => {
	const state = getStore().getState();

	sendTag({
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		event: `accommodations_show_more`,
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnExternalPayment = status => {
	const state = getStore().getState();

	sendTag({
		event: `book_external_payment_status`,
		status,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnPaymentFailed = (status, paymentType) => {
	const state = getStore().getState();

	const tagData = {
		event: `payment_failure`,
		status,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	};
	if (paymentType) {
		tagData.paymentType = paymentType;
	}
	sendTag(tagData);
};

export const sendTagOnMyBookingsPaymentFailed = status => {
	sendTag({
		event: `my_bookings_payment_failure`,
		status,
	});
};

export const sendTagOn3DSRedirect = () => {
	const state = getStore().getState();
	sendTag({
		event: `3ds_redirect`,
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnLogout = () => {
	const state = getStore().getState();
	sendTag({
		event: "logout",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnSocialShare = channel => {
	const state = getStore().getState();
	sendTag({
		event: `share_product_page`,
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		channel,
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnLogoutFromDevice = tokenId => {
	const state = getStore().getState();
	sendTag({
		event: "logout_from_device",
		user: mapUserStoreToTag(state),
		tokenId: tokenId || "",
	});
};

export const sendTagOnLogoutFromAllDevice = () => {
	const state = getStore().getState();
	sendTag({
		event: "logout_from_all_device",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnSecurityPageLoad = () => {
	const state = getStore().getState();
	sendTag({
		event: "security_page",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnTeasingPageLoad = () => {
	sendTag({
		event: "teasing_page",
	});
};

export const checkOurNewOffer = () => {
	sendTag(Object.assign({}, { event: "check_our_new_offer" }, {}));
};

export const sendTagOnSearchBookingPageLoad = () => {
	sendTag({
		event: `site-institutionnel-page`,
	});
};

export const sendTagOnRecoverBookingDataPageLoad = () => {
	sendTag({
		event: `recover-booking-data-page`,
	});
};

export const sendTagOnBookingSearch = ({ bookingReference, credential }) => {
	sendTag({
		event: `my-booking-search`,
		bookingReference,
		credential,
	});
};

export const sendTagOnRecoverBookingData = ({ phone }) => {
	sendTag({
		event: `recover-booking-data`,
		phone,
	});
};

export const sendTagOnSponsorshipSubscribeSuccess = ({ parentSponsorId, sponsored }) => {
	sendTag({
		event: `sponsorship_subscribe_success`,
		sponsorid: parentSponsorId,
		email: sponsored,
	});
};

export const sendTagOnSponsorshipSubscribeFailed = ({ status, sponsored }) => {
	sendTag({
		event: `sponsorship_subscribe_failed`,
		status,
		email: sponsored,
	});
};

export const sendTagOnCreditsBurned = ({ usableCredits, totalCredits }) => {
	const state = getStore().getState();

	sendTag({
		event: `sponsorship_credits_burned`,
		usableCredits,
		totalCredits,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnFetchCreditsFailed = ({ status }) => {
	const state = getStore().getState();
	sendTag({
		event: `sponsorship_fetch_credits_failed`,
		quote: mapQuoteStoreToTag(state),
		user: mapUserStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
		status,
	});
};

export const sendTagOnSponsorshipPageLoad = () => {
	const state = getStore().getState();
	sendTag({
		event: "sponsorship_page",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnMyCreditsPageLoad = () => {
	const state = getStore().getState();
	sendTag({
		event: "my_credits_page",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnSponsorshipInvite = (channel, sponsorId) => {
	sendTag({
		event: "sponsorship_invite_social",
		channel,
		sponsorId,
	});
};

export const sendTagOnSponsorshipInviteByEmail = ({ sponsorId, firstName, lastName, emails }) => {
	sendTag({
		event: "sponsorship_invite_mail",
		sponsorId,
		firstName,
		lastName,
		emails: JSON.stringify(emails),
	});
};

export const sendTagOnOauthAccessTokenFailed = () => {
	const state = getStore().getState();
	sendTag({
		event: "get_oauth_access_token_failed",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnPageNotFound = () => {
	let state = getStore().getState();
	sendTag({
		event: "page_not_found",
		askedUrl: window.location.href || "",
		referrer: document.referrer || "",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnChangeLanguage = language => {
	sendTag({
		event: "change_language",
		language,
	});
};

export const sendTagOnClickFilterMobile = () => {
	sendTag({
		event: "listingPage_filter_mobile_open",
	});
};

export const sendDataOnFetchProfileSuccess = () => {
	const state = getStore().getState();

	sendTag({
		event: "payment_fetch_profile_success",
		user: mapUserStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendDataOnClickCollectionPermanente = () => {
	const state = getStore().getState();

	sendTag({
		event: "listingPage_collection_permanente_click",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnException = ({ error, info }) => {
	const state = getStore().getState();

	sendTag({
		event: "exception_front",
		user: mapUserStoreToTag(state),
		errorMessage: error.message,
		errorStackTrace: error.stack,
		info: info.componentStack,
	});
};

export const sendTagOnNavlogClick = ({ productUri = "", quotationURL = "" }) => {
	const state = getStore().getState();

	sendTag({
		event: "listingPage_navlog_click",
		user: mapUserStoreToTag(state),
		productUri,
		quotationURL,
	});
};

export const sendTagOnLookAlikeProductsSeenClick = ({ products = [] }) => {
	const state = getStore().getState();

	const productUris = products.map(product => product.uri);

	sendTag({
		event: "lookalike_Product_view",
		user: mapUserStoreToTag(state),
		products: [...productUris],
	});
};

export const sendTagOnLookAlikeProductClick = ({ productUri = "", position }) => {
	const state = getStore().getState();

	sendTag({
		event: "lookalike_Product_click",
		user: mapUserStoreToTag(state),
		position: `${position}`,
		productUri,
	});
};

export const sendTagOnNavlogPrevious = () => {
	sendTag({
		event: "last_seen_products_previous_click",
	});
};

export const sendTagOnNavlogProductClick = product => {
	sendTag({
		event: "last_seen_product_click",
		product,
	});
};

export const sendTagOnNavlogNext = nextProduct => {
	sendTag({
		event: "last_seen_products_next_click",
		product: nextProduct,
	});
};

export const sendTagOnNavlogSeenProducts = products => {
	sendTag({
		event: "last_seen_products_view",
		products,
	});
};

export const sendTagOnLogoRedirect = url => {
	sendTag({
		event: "brand_logo_header_click",
		url,
	});
};

export const sendTagOnBackButton = url => {
	sendTag({
		event: "back_button_header_click",
		url,
	});
};

export const sendTagOnOpenProductCarouselDesktop = () => {
	sendTag({
		event: "open_photos_carousel_desktop_click",
	});
};

export const sendTagOnSwipeProductCarouselMobile = () => {
	sendTag({
		event: "photos_carousel_mobile_swipe",
	});
};

export const sendTagOnBookingBreadcrumbClick = step => {
	sendTag({
		event: "breadcrumb_click",
		step,
	});
};

export const sendTagOnMediaClickDesktop = title => {
	sendTag({
		event: "media_click_article_desktop",
		title,
	});
};

export const sendTagOnMediaClickMobile = () => {
	sendTag({
		event: "media_click_article_mobile",
	});
};

export const sendTagOnMediaCloseArticle = () => {
	sendTag({
		event: "media_close_article",
	});
};

export const sendTagOnMediaViewOffers = () => {
	sendTag({
		event: "media_view_offers",
	});
};

export const sendTagOnScrollInsurance = () => {
	sendTag({
		event: "insurance_view",
	});
};

export const sendTagOnSelectPricedInsurance = code => {
	sendTag({
		event: "insurance_click_add",
		code,
	});
};

export const sendTagOnSelectWithoutInsurance = () => {
	sendTag({
		event: "insurance_click_without_insurance",
	});
};

export const sendTagOnCompareInsurance = () => {
	sendTag({
		event: "insurance_click_compare",
	});
};

export const sendTagOnClickInsuranceDetailsLink = () => {
	sendTag({
		event: "insurance_click_more_details",
	});
};

export const sendTagOnQuoteViewAccommodation = () => {
	sendTag({
		event: "quote_view_room",
	});
};

export const sendTagOnQuoteViewFlight = () => {
	sendTag({
		event: "quote_view_flight",
	});
};

export const sendTagOnQuoteViewLuggage = () => {
	sendTag({
		event: "quote_view_luggage",
	});
};

export const sendTagOnQuoteViewTransfer = () => {
	sendTag({
		event: "quote_view_transfert",
	});
};

export const sendTagOnQuoteViewActivity = () => {
	sendTag({
		event: "quote_view_activity",
	});
};

export const sendTagOnQuoteViewCTA = () => {
	sendTag({
		event: "quote_view_CTA",
	});
};

export const sendTagOnPaymentViewContact = () => {
	sendTag({
		event: "payment_view_contact",
	});
};

export const sendTagOnPaymentViewInformation = () => {
	sendTag({
		event: "payment_view_information",
	});
};

export const sendTagOnPaymentViewPayment = () => {
	sendTag({
		event: "payment_view_Payment",
	});
};

export const sendTagOnPaymentViewCTA = () => {
	sendTag({
		event: "payment_view_CTA",
	});
};

export const sendTagOnSearchPageViewed = () => {
	sendTag({
		event: "search_page",
	});
};

export const sendTagDepositNoteViewed = () => {
	sendTag({
		event: "Search_deposit_available",
	});
};

export const sendTagDepositOnQuotePage = () => {
	sendTag({
		event: "Quote_deposit_available",
	});
};

export const sendTagOnBreakPageViewed = () => {
	sendTag({
		event: "break_page",
	});
};
