import {
	SEARCH_BOOKING_SUCCESS,
	FETCH_MY_BOOKINGS_SUCCESS,
	PAY_DUE_BOOKING_SUCCESS,
} from "app/actionTypes";
import { MY_BOOKINGS_STATUS } from "app/constants";
import indexOf from "lodash/indexOf";
import find from "lodash/find";

const initialMyBookings = {
	status: MY_BOOKINGS_STATUS.LOADING,
	futureBookings: [],
	pastBookings: [],
	timestamp: undefined,
};

export default function myBookingReducer(myBookings = initialMyBookings, action) {
	switch (action.type) {
		case PAY_DUE_BOOKING_SUCCESS: {
			const newBooking = action.res.data.booking;
			const myBookingsCopy = Object.assign({}, myBookings);
			const index = indexOf(
				myBookingsCopy.futureBookings,
				find(myBookingsCopy.futureBookings, {
					bookingReference: newBooking.bookingReference,
				})
			);
			myBookingsCopy.futureBookings.splice(index, 1, newBooking);
			myBookingsCopy.status = action.res.data.status;
			return myBookingsCopy;
		}
		case FETCH_MY_BOOKINGS_SUCCESS:
		case SEARCH_BOOKING_SUCCESS:
			return Object.assign({}, myBookings, {
				status: action.res.data.status,
				futureBookings: action.res.data.futureBookings || [],
				pastBookings: action.res.data.pastBookings || [],
				timestamp: action.res.data.timestampISO8601,
			});
		default:
			return myBookings;
	}
}
