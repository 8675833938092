import React from "react";
import { FormattedMessage } from "react-intl";
import "./TechnicalErrorRegistrationErrorDefault.scss";
import ContactOurExperts from "app/pages/.shared/ContactOurExperts";

class TechnicalErrorRegistrationErrorDefault extends React.PureComponent {
	render() {
		return (
			<div className="technical-error">
				<div className="technical-error__intro">
					<div className="dont-leave__contact">
						<ContactOurExperts
							showFAQLink={true}
							message={
								<FormattedMessage id="technical.error.registration.error.message" />
							}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default TechnicalErrorRegistrationErrorDefault;
