import { combineValidators } from "revalidate";
import { mustAcceptTerms } from "../../../utils/form/validators";
import { emailFieldValidator, passwordFieldValidator } from "app/utils/form/fieldValidators";
import validationMessages from "app/utils/form/validationMessages.js";

export const fields = ["email", "password", "terms"];

export const validate = combineValidators({
	email: emailFieldValidator,
	password: passwordFieldValidator,
	terms: mustAcceptTerms({
		message: validationMessages.termsRequired,
	}),
});
