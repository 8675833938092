import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import Breadcrumb from "app/pages/Breadcrumb/Breadcrumb.jsx";
import BreadcrumbItem from "app/pages/Breadcrumb/BreadcrumbItem.jsx";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./booking-header.scss";
import SimpleHeaderContainer from "app/pages/Header/SimpleHeader/SimpleHeaderContainer";
import { matchPath } from "react-router-dom";
import { sendTagOnBookingBreadcrumbClick } from "app/utils/analytics";

const BookingHeader = ({ location, quoteExitUrl }) => {
	const currentPathname = location.pathname;
	const isBookingAuthPage = matchPath(currentPathname, { path: "/booking/auth" });

	const isFirstStep =
		matchPath(currentPathname, {
			path: quoteExitUrl,
		}) ||
		matchPath(currentPathname, {
			path: "/booking/search",
		});

	const isQuotationPage = matchPath(currentPathname, {
		path: "/booking/quote",
	});

	const isPaiementPage = matchPath(currentPathname, {
		path: "/booking/payment",
	});

	return (
		<header className="booking-header">
			<SimpleHeaderContainer />
			<div className="booking-header__breadcrumb">
				<Breadcrumb>
					<BreadcrumbItem
						to={quoteExitUrl}
						isActive={Boolean(isFirstStep)}
						isPassed={
							Boolean(isPaiementPage) || Boolean(isQuotationPage) || isBookingAuthPage
						}
						label={<FormattedMessage id="booking.breadcrumb.step.1" />}
						disableClick={true}
						onClick={() => sendTagOnBookingBreadcrumbClick("availabilities")}
					/>
					<BreadcrumbItem
						to={"/booking/quote"}
						isActive={Boolean(isQuotationPage)}
						isDisabled={Boolean(isFirstStep)}
						isPassed={Boolean(isPaiementPage) || isBookingAuthPage}
						label={<FormattedMessage id="booking.breadcrumb.step.2" />}
						disableClick={true}
						onClick={() => sendTagOnBookingBreadcrumbClick("options")}
					/>
					<BreadcrumbItem
						isActive={Boolean(isPaiementPage)}
						isDisabled={Boolean(isFirstStep) || Boolean(isQuotationPage)}
						label={<FormattedMessage id="booking.breadcrumb.step.3" />}
						disableClick={true}
						onClick={() => sendTagOnBookingBreadcrumbClick("payment")}
					/>
				</Breadcrumb>
			</div>
		</header>
	);
};

BookingHeader.propTypes = {
	quoteExitUrl: PropTypes.string,
	location: PropTypes.object,
};

BookingHeader.defaultProps = {
	location: {},
	quoteExitUrl: "",
};

export default registerStyle(style)(BookingHeader);
