import { createSelector } from "reselect";
import get from "lodash/get";

const getPartner = state => state.partner;

export const getPartnerCode = state => state.partner && state.partner.code;

export const getPartnerRedirectionLink = state => state.partner.partnerRedirectionLink || {};

export const getSocialNetworksLinks = state => state.partner.socialNetworks || {};

export const getBrandDisplayName = createSelector(
	[getPartner],
	partner => {
		return get(partner, "seo.siteTitle") || "";
	}
);

export const shouldApplyCasinoSansFrais = createSelector(
	[getPartnerCode],
	partnerCode => {
		// const today = new Date();
		// const casinoSansFraisStartDate = new Date("2020-01-08T00:00:01");
		// const casinoSansFraisPartnerCodes = ["AFFR", "EKFR", "HPFR", "CDFR", "PSVP"];

		// const shouldApplySansFrais = casinoSansFraisPartnerCodes.includes(partnerCode) && today >= casinoSansFraisStartDate;

		const shouldApplySansFrais = false;

		return shouldApplySansFrais;
	}
);
