import PerfectApp from "./PerfectApp.jsx";
import PropTypes from "prop-types";
import { getCredentialsWithTokenId } from "app/pages/Auth/AuthActions";
import { connect } from "react-redux";
import React from "react";
import { bindActionCreators } from "redux";
import Cookies from "js-cookie";
import { COOKIES_AUTH_EXPIRES, STRATEGY } from "app/constants";
import { isWhiteLabel } from "app/reducers/brandSelector";
import { getPartnerData } from "app/reducers/partnerActionCreators";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import { strategyShape } from "app/utils/propTypes";

class PerfectAppContainer extends React.Component {
	componentDidMount() {
		// Les nouveaux tokens disposent désormais d'un tokenId.
		// Au démarrage de l'appli, on verifie si le token dispose d'un tokenId
		// si ce n'est pas le cas, alors on en regénère un nouveau avec tokenId et on remplace l'ancien
		// Seulement ppour les brands avec api membre
		if (this.props.strategy === STRATEGY.SIGNUP_FIRST) {
			const token = this.props.token;
			if (token) {
				this.props.getCredentialsWithTokenId(token);
			}
		}

		const auth = Cookies.get("auth");

		// si un ancien cookie auth est présent ...
		if (auth) {
			// ... on le met dans le cookie fr-FR
			Cookies.remove("auth");
			Cookies.set("auth", auth, { expires: COOKIES_AUTH_EXPIRES, path: "/fr-FR" });
		}

		const partner = Cookies.get("partner");

		if (partner) {
			Cookies.remove("partner");
			Cookies.set("partner", partner, { path: "/fr-FR" });
		}

		this.props.getPartnerData();
	}

	render() {
		return <PerfectApp {...this.props} />;
	}
}

PerfectAppContainer.propTypes = {
	getPartnerData: PropTypes.func,
	getCredentialsWithTokenId: PropTypes.func,
	token: PropTypes.string,
	shop: PropTypes.string,
	strategy: strategyShape,
};

const mapStateToProps = state => {
	return {
		token: state.auth.token,
		shop: state.shop,
		brand: state.brand.code,
		strategy: state.partner.strategy,
		isAuthenticated: isAuthenticated(state),
		isWhiteLabel: isWhiteLabel(state),
		productsVisibility: state.productsVisibility,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ getCredentialsWithTokenId, getPartnerData }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PerfectAppContainer);
