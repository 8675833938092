import React from "react";
import { FormattedMessage } from "react-intl";
import "./TechnicalErrorRegistrationExpired.scss";
import RelativeLink from "app/pages/.shared/RelativeLink";
import PropTypes from "prop-types";

class TechnicalErrorRegistrationExpired extends React.PureComponent {
	render() {
		const { teasingEndDate } = this.props;

		const today = new Date();
		const endDate = new Date(teasingEndDate);

		const isTeasing = today <= endDate;

		return (
			<div className="technical-error">
				<div className="technical-error__intro">
					<FormattedMessage id="technical.error.registration.expired.message" />

					<div className="technical-error__cta">
						<RelativeLink
							to={{ pathname: isTeasing ? "/teasing" : "/home/signup" }}
							design="primary"
						>
							<FormattedMessage id="technical.error.registration.expired.cta.label" />
						</RelativeLink>
					</div>
				</div>
			</div>
		);
	}
}

TechnicalErrorRegistrationExpired.propTypes = {
	teasingEndDate: PropTypes.string,
};

export default TechnicalErrorRegistrationExpired;
