import "./patchReact";
import React from "react";
import ReactDOM from "react-dom";
import { Route, Router } from "react-router-dom";
import getHistory from "app/configureHistory";
import { loadableReady } from "@loadable/component";
import { Provider } from "react-redux";
import { configureStore } from "../app/configureStore";
import { initPersistor } from "app/utils/persistor";
import ResponsiveStateProvider from "app/pages/.shared/responsive/ResponsiveStateProvider.jsx";
import { addLocaleData, IntlProvider } from "react-intl";
import { BRANDS } from "app/constants";
import env from "app/utils/env";
import PerfectAppContainer from "app/PerfectAppContainer";
import ErrorBoundary from "app/pages/.shared/ErrorBoundary";
import { fetchMessages } from "src/intl/intlUtils";
import queryString from "query-string";
import { virtualPageLoading } from "app/utils/analytics";
import { fetchABTestsActiveCampaignsRequest } from "app/reducers/abTestsActionCreators";
import { initializeSentry } from "app/utils/sentry";

//scoped references
const initialState = window.__INITIAL_STATE__ || {};
const { shop, brand, envVars } = initialState;
const locale = shop.slice(0, 2);
const store = configureStore(initialState);

initializeSentry(store.getState());

let intl;

// On migre toutes les données du localstorage dans la shop par défaut fr-FR pour ne pas être considéré comme déconnecté
// lors du passage vers secu-af
if (window.localStorage) {
	const authData = window.localStorage.getItem("reduxPersist:auth");
	if (authData) {
		window.localStorage.setItem("reduxPersist:fr-FR:auth", authData);
		window.localStorage.removeItem("reduxPersist:auth");
	}

	const emailData = window.localStorage.getItem("reduxPersist:email");
	if (emailData) {
		window.localStorage.setItem("reduxPersist:fr-FR:email", emailData);
		window.localStorage.removeItem("reduxPersist:email");
	}

	const cookiePolicyData = window.localStorage.getItem("reduxPersist:isCookiePolicyAccepted");
	if (cookiePolicyData) {
		window.localStorage.setItem("reduxPersist:fr-FR:isCookiePolicyAccepted", cookiePolicyData);
		window.localStorage.removeItem("reduxPersist:isCookiePolicyAccepted");
	}

	const partnerData = window.localStorage.getItem("reduxPersist:partner");
	if (partnerData) {
		window.localStorage.setItem("reduxPersist:fr-FR:partner", partnerData);
		window.localStorage.removeItem("reduxPersist:partner");
	}
}

asyncIntlPolyfill();

/*
 ** async execution sequence
 */

function asyncIntlPolyfill() {
	// load polyfill for safari
	// @see http://formatjs.io/guides/runtime-environments/#polyfill-browsers
	if (!global.Intl) {
		import(/* webpackChunkName: "intl-polyfill" */ "intl").then(() => {
			import(/* webpackChunkName: "intl-locale-data-polyfill-[request]" */ `intl/locale-data/jsonp/${locale}`).then(
				() => {
					asyncMessagesLoader();
				}
			);
		});
	} else {
		asyncMessagesLoader();
	}
}

function asyncMessagesLoader() {
	const apiURL = env("CONTENT_API_URL") || env("BASE_URL");
	import(/* webpackChunkName: "locale-[request]" */ `react-intl/locale-data/${locale}`).then(
		localeData => {
			addLocaleData([...localeData.default]);

			fetchMessages(apiURL, brand.code, shop).then(messages => {
				// Note : Seulement cote front (voir i18nMiddleware pour la generation serveur)

				intl = {
					messages: messages,
					locale: shop,
				};
				asyncStoreLoader();
			});
		}
	);
}

function asyncStoreLoader() {
	initPersistor(store, { shop }, renderApp);
}

function renderApp() {
	const injectAnalyticScripts = () => {
		let gtmId = envVars.GTM_ID;

		// Hack mais temporaire. Pour eboutic qui est une brand VP mais un shop fr-CH et de-CH, il faut un GTM_ID
		// different de la brand VP. Du coup on override ici. A supprimer quand Eboutic et Vente privee seront unifiés sous une meme
		// brand VeePee

		if (brand.code === BRANDS.VP && (shop === "fr-CH" || shop === "de-CH")) {
			gtmId = "GTM-MZS992F";
		}

		const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${gtmId}');`;

		const script = document.createElement("script");
		script.text = GTM_SCRIPT;
		const head = document.getElementsByTagName("head")[0];
		head.appendChild(script);
	};

	const checkEnvironnementVariables = () => {
		const {
			BASE_URL,
			QUOTE_API_URL,
			FACEBOOK_APP_ID,
			GOOGLE_API_KEY,
			GTM_ID,
			ADYEN_API_KEY,
		} = envVars;

		if (!BASE_URL) {
			throw new Error("Missing environment variable : BASE_URL");
		}

		if (!QUOTE_API_URL) {
			throw new Error("Missing environment variable : QUOTE_API_URL");
		}

		if (!FACEBOOK_APP_ID) {
			throw new Error("Missing environment variable : FACEBOOK_APP_ID");
		}

		if (!GOOGLE_API_KEY) {
			throw new Error("Missing environment variable : GOOGLE_API_KEY");
		}

		if (!GTM_ID) {
			throw new Error("Missing environment variable : GTM_ID");
		}

		if (!ADYEN_API_KEY) {
			throw new Error("Missing environment variable : ADYEN_API_KEY");
		}
	};

	checkEnvironnementVariables();

	const app = (
		<Provider store={store}>
			<ResponsiveStateProvider>
				<IntlProvider {...intl}>
					<Router history={getHistory(shop)}>
						<Route
							path="/"
							render={({ location }) => {
								const query = queryString.parse(location.search);

								// virtualPageLoading à envoyer à chaque changement d'url mais également au premier chargement de la page
								virtualPageLoading({
									productId: location.state && location.state.productId,
									pageTitle: location.pathname,
									landingPageTopic: query.topic,
									shop,
								});

								/* abTests initialization */
								store.dispatch(fetchABTestsActiveCampaignsRequest());

								return (
									<ErrorBoundary>
										<PerfectAppContainer />
									</ErrorBoundary>
								);
							}}
						/>
					</Router>
				</IntlProvider>
			</ResponsiveStateProvider>
		</Provider>
	);

	loadableReady(() => {
		ReactDOM.hydrate(app, document.querySelector("#perfectstay"), () => {
			// timeout pour s'assurer que les photos chargent bien après le chat et le script Google Analytics
			// eslint-disable-next-line max-nested-callbacks
			setTimeout(() => {
				injectAnalyticScripts();
			}, 500);
		});
	});
}

if (module.hot) {
	module.hot.accept("app/PerfectAppContainer", () => {
		renderApp();
	});
}
