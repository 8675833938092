import { connect } from "react-redux";
import SimpleHeader from "app/pages/Header/SimpleHeader/SimpleHeader.jsx";
import { getFallbackRedirectUrl } from "src/@brand/appConstants";
import { matchPath, withRouter } from "react-router-dom";
import { getQuoteExitUri } from "app/pages/Header/navigationSelector";
import { isProductPage } from "app/utils/urlDataExtractor";
import { getContextCallCenter } from "app/pages/Booking/bookingSelectors";

const mapStateToProps = (state, props) => {
	let productUri = state.io.quoteExitUrl || state.ficheProduit.uri;

	const currentPathname = props.location.pathname;

	const quoteExitUrl = getQuoteExitUri(state);

	const isQuotationPage = matchPath(currentPathname, {
		path: "/booking/quote",
	});

	const isPaiementPage = matchPath(currentPathname, {
		path: "/booking/payment",
	});

	const isConfirmationPage = matchPath(currentPathname, {
		path: "confirmation",
	});

	const isAuthPage =
		matchPath(currentPathname, {
			path: "/auth",
		}) ||
		matchPath(currentPathname, {
			path: "/booking/auth",
		});

	const isLandingProductPage = props.match.path === "/product/:productUri";
	const isFicheProduitPage = isProductPage(props.match.path);

	const contextCallCenter = getContextCallCenter({
		isPaymentPage: isPaiementPage,
		isQuotationPage,
		isConfirmationPage,
	})(state);

	//dans le cas où nous somme sur /booking/search on initialise à la fiche produit
	let previousUrl = productUri;

	if (isQuotationPage) {
		// sur la page de quotation, on regarde si nous devons renvoyer vers la search ou fiche produit
		previousUrl = quoteExitUrl;
	} else if (isPaiementPage) {
		previousUrl = "/booking/quote";
	} else if (isFicheProduitPage) {
		previousUrl = getFallbackRedirectUrl();
	}

	return {
		previousUrl,
		brand: state.brand.code,
		operatorEmail: state.auth.operatorEmail,
		hideBackButton: isAuthPage || isLandingProductPage,
		...contextCallCenter,
		isInApp: state.navigation.isInApp,
	};
};

export default withRouter(connect(mapStateToProps)(SimpleHeader));
