import { connect } from "react-redux";
import NoteCasino from "app/pages/Footer/NoteCasino/NoteCasino";

const mapStateToProps = state => {
	const payment4x = state.payment.payment4x;

	return {
		minAmount: payment4x.minAmount !== undefined ? payment4x.minAmount : 400,
		maxAmount: payment4x.maxAmount,
		casinoCGV: state.documents.payment.casinoCGV,
	};
};

export default connect(mapStateToProps)(NoteCasino);
