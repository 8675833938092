import { FETCH_PARTNER_DATA_SUCCESS } from "app/reducers/partnerActionTypes";
import isEmpty from "lodash/isEmpty";

const INITIAL_STATE = {
	officeHours: [],
	callPrice: "",
	contextPhones: [],
	helpContactUrl: "",
	email: "",
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_PARTNER_DATA_SUCCESS: {
			const { callCenter: callCenterData = {} } = action.res.data || {};

			if (isEmpty(callCenterData)) {
				return state;
			}

			if (callCenterData.contextPhones && callCenterData.contextPhones.length > 0) {
				return {
					...callCenterData,
					contextPhones: callCenterData.contextPhones.map(contextPhone => ({
						...contextPhone,
						displayPhone: contextPhone.displayPhone
							? contextPhone.displayPhone.split(" ")
							: undefined,
						displayInternationalPhone: contextPhone.displayInternationalPhone
							? contextPhone.displayInternationalPhone.split(" ")
							: undefined,
					})),
				};
			}

			return callCenterData;
		}
		default: {
			return state;
		}
	}
};
