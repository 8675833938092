import { SET_EMAIL, AUTH_SUCCESS, LOGOUT, FETCH_PROFILE_SUCCESS } from "app/actionTypes";

export default function emailReducer(emailState = null, action) {
	switch (action.type) {
		case SET_EMAIL:
			return action.email || emailState;
		case AUTH_SUCCESS:
			return action.email || emailState;
		case FETCH_PROFILE_SUCCESS: {
			const profile = action.profile;
			return profile.email || emailState;
		}
		case LOGOUT:
			return null;
		default:
			return emailState;
	}
}
