import {
	BOOK_EXTERNAL_HTML_REQUEST,
	BOOK_EXTERNAL_PAYMENT_REQUEST,
	BOOK_EXTERNAL_PAYMENT_SUCCESS,
	BOOK_REQUEST,
	BOOK_SUCCESS,
	CHECK_AVAILABILITIES_SUCCESS,
	PRE_BOOK_REQUEST,
	PRE_BOOK_SUCCESS,
	SEND_PROMOTION_CODE_SUCCESS,
	UPDATE_EXTERNAL_BOOK_STATUS,
} from "app/actionTypes";
import { CHANGE, FOCUS } from "redux-form/lib/actionTypes";
import { BOOK_STATUS, PAYMENT_METHODS } from "app/constants";
import get from "lodash/get";

const initialPayment = {
	bookStatus: undefined,
	dateOfBirthRequired: false,
	payment4x: {
		minAmount: 400,
		maxAmount: 3000,
	},
	paymentSwissbilling: {
		maxAmount: 3500,
	},
	showFrequentTravellerCardInput: false,
	paymentTypes: [],
	insurances: {},
	timestamp: undefined, // timestamp utilisé pour l'encryption adyen CB
};

export default (payment = initialPayment, action) => {
	switch (action.type) {
		case PRE_BOOK_REQUEST:
			// éviter que le logo de paiement 4X disparaisse lors du clic sur le bouton Réserver maintenant de la page Quote
			return Object.assign({}, initialPayment, {
				paymentTypes: payment.paymentTypes,
				payment4x: payment.payment4x, // ne pas réécraser les infos de paiement 4x par brand (elles sont récupérées une seule fois au démarrage de l'application)
				paymentSwissbilling: payment.paymentSwissbilling, // ne pas réécraser les infos de paiement 4x par brand (elles sont récupérées une seule fois au démarrage de l'application)
			});
		case BOOK_REQUEST:
			return Object.assign({}, payment, { bookStatus: undefined });
		case BOOK_SUCCESS:
		case BOOK_EXTERNAL_PAYMENT_SUCCESS:
			return Object.assign({}, payment, { bookStatus: action.res.data.status });
		case PRE_BOOK_SUCCESS: {
			const paymentTypes = get(action, "res.data.paymentTypes") || [];
			const payment4xType =
				paymentTypes.find(
					paymentType => paymentType.paymentType === PAYMENT_METHODS.CASINO
				) || initialPayment.payment4x;
			const paymentSwissbillingType =
				paymentTypes.find(
					paymentType => paymentType.paymentType === PAYMENT_METHODS.SWISS_BILLING
				) || initialPayment.paymentSwissbilling;
			return Object.assign({}, payment, {
				insurances: action.res.data.insurances || {},
				paymentTypes: action.res.data.paymentTypes || [],
				timestamp: action.res.data.timestampISO8601,
				dateOfBirthRequired: action.res.data.dobRequired,
				showFrequentTravellerCardInput: action.res.data.showFrequentTravellerCardInput,
				bookStatus: undefined,
				payment4x: {
					minAmount: payment4xType.minAmount,
					maxAmount: payment4xType.maxAmount,
				},
				paymentSwissbilling: { maxAmount: paymentSwissbillingType.maxAmount },
			});
		}
		case SEND_PROMOTION_CODE_SUCCESS:
		case CHECK_AVAILABILITIES_SUCCESS:
			return Object.assign({}, payment, {
				paymentTypes: action.res.data.paymentTypes || [],
			});
		case BOOK_EXTERNAL_PAYMENT_REQUEST:
		case BOOK_EXTERNAL_HTML_REQUEST:
			return Object.assign({}, payment, {
				bookStatus: BOOK_STATUS.LOADING,
			});
		case UPDATE_EXTERNAL_BOOK_STATUS:
			return Object.assign({}, payment, {
				bookStatus: action.bookStatus,
			});
		case FOCUS: // focus pour les inputs et change pour les radios
		case CHANGE: {
			// si erreur dans la page de paiement du au bookStatus, on scroll automatiquement vers le message d'erreur.
			// Tant que le bookStatus n'est pas reinitialisé, on scroll a chaque fois vers le message d'erreur à chaque interaction avec le
			// formulaire de paiement.
			// Pour éviter cela, on reinitialise le bookStatus dès que l'on interagit avec le formulaire.
			if (action.form === "booking-payment-form" && payment.bookStatus) {
				// eslint-disable-line consistent-return
				return Object.assign({}, payment, {
					bookStatus: undefined,
				});
			}

			return payment;
		}
		default:
			return payment;
	}
};
