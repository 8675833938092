import { createSelector } from "reselect";

const getABTestActiveCampaign = state => state.abTests.activeCampaigns;
const getCampaignID = (state, campaignID = null) => campaignID;
export const getABTestsStatus = state => state.abTests.status; // TODO : remove after ABTest

export const getActiveCampaign = createSelector(
	[getABTestActiveCampaign, getCampaignID],
	(activeCampaigns = [], campaignID) => {
		const activeCampaign = activeCampaigns.find(campaign => {
			return campaign.campaignID === campaignID;
		});
		return activeCampaign;
	}
);

export const getActiveVariationID = createSelector(
	[getActiveCampaign],
	(campaign = {}) => {
		return campaign.variationID;
	}
);
