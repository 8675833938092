import { START_PRINT } from "app/actionTypes";
import { RESET_PRINT } from "../actionTypes";

const initialState = {
	printStarted: undefined,
};

export default function emailReducer(print = initialState, action) {
	switch (action.type) {
		case START_PRINT:
			return {
				printStarted: true,
			};
		case RESET_PRINT:
			return {
				printStarted: false,
			};
		default:
			return print;
	}
}
