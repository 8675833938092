import BookingHeader from "./BookingHeader.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getQuoteExitUri } from "app/pages/Header/navigationSelector";

const mapStateToProps = state => {
	return {
		quoteExitUrl: getQuoteExitUri(state),
		resolution: state.resolution,
	};
};

export default connect(mapStateToProps)(withRouter(BookingHeader));
