import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import "./SaveUpTo.scss";

const SaveUpTo = ({ value }) => {
	return (
		<div className="save-up-to">
			<FormattedMessage
				id="save.up.to.label"
				values={{
					amount: <span className="save-up-to__value">{value}</span>,
					unit: <sup className="save-up-to__unit">%</sup>,
				}}
				// on utilise une div pour ne pas impacter les span via le CSS qui ne s'appliquera qu'à cette unique div
				tagName="div"
			/>
		</div>
	);
};

SaveUpTo.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default React.memo(SaveUpTo);
