import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import RelativeLink from "app/pages/.shared/RelativeLink";

const HelpLink = ({ helpContactUrl }) => (
	<RelativeLink rel="noopener noreferrer" target="_blank" to={{ pathname: helpContactUrl }}>
		<FormattedMessage id="menu.item.help" />
	</RelativeLink>
);

HelpLink.propTypes = { helpContactUrl: PropTypes.string };

export default HelpLink;
