import { UPDATE_QUOTE_EXIT_URL } from "app/reducers/ioActionTypes";

const initialState = {
	quoteExitUrl: undefined,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_QUOTE_EXIT_URL:
			return {
				...state,
				quoteExitUrl: action.quoteExitUrl,
			};
		default:
			return { ...initialState, ...state };
	}
};
