import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import "./hamburger.scss";

const Hamburger = ({ isActive, onToogle }) => {
	const hamburgerClass = classNames({
		"hamburger hamburger--collapse": true,
		"hamburger--active": isActive,
	});

	return (
		<button className={hamburgerClass} type="button" onClick={onToogle}>
			<span className="hamburger-box">
				<span className="hamburger-inner" />
			</span>
		</button>
	);
};

Hamburger.propTypes = {
	isActive: PropTypes.bool,
	onToogle: PropTypes.func,
};

export default React.memo(Hamburger);
