import { persistStore } from "redux-persist";
import createFilter from "redux-persist-transform-filter";
import { asyncSessionStorage } from "redux-persist/storages";

const LOCALE_STORAGE_WHITELIST = ["auth", "email", "isCookiePolicyAccepted"];
const SESSION_STORAGE_WHITELIST = [
	"ficheProduit",
	"booking",
	"quotation",
	"navigation",
	"payment",
	"partnerData",
	"merchandising",
	"productsFilterMetaData",
	"partner",
];

// id, uri, category, name, resort, photos[0].url pour les info produit dans le tunnel
// le reste pour analytics lors d'un reload par exemple
const saveFicheProduitIdFilter = createFilter("ficheProduit", ["id", "uri"]);

const paymentFilter = createFilter("payment", [
	"insurances",
	"timestamp",
	"paymentTypes",
	"paymentModes",
	"dateOfBirthRequired",
	"showFrequentTravellerCardInput",
	"payment4x",
	"paymentSwissbilling",
]);

const navigationFilter = createFilter("navigation", ["isInApp"]);

const partnerFilter = createFilter("partner", ["strategy"]);

// on persiste le code merch pour le bouton back
const merchandising = createFilter("merchandising", ["current"]);

export const initPersistor = (store, options, cb) => {
	const { shop } = options;

	persistStore(
		store,
		{
			whitelist: LOCALE_STORAGE_WHITELIST,
			keyPrefix: `reduxPersist:${shop}:`,
		},
		() => {
			persistStore(
				store,
				{
					whitelist: SESSION_STORAGE_WHITELIST,
					keyPrefix: `reduxPersist:${shop}:`,
					storage: asyncSessionStorage,
					transforms: [
						saveFicheProduitIdFilter,
						paymentFilter,
						partnerFilter,
						merchandising,
						navigationFilter,
					],
				},
				cb
			);
		}
	);
};
