import {
	RESET_PRODUCTS_FILTER,
	SET_PRODUCTS_FILTER_BY,
	UNSET_PRODUCTS_FILTER_BY,
} from "app/actionTypes";

import {
	REPLACE_PRODUCTS_FILTERS,
	SET_PRODUCTS_FILTERS,
	UNSET_PRODUCTS_FILTERS,
} from "app/pages/Listing/ProductFilters/productsFilterActionsTypes";
import {
	finishLoadingBar,
	showLoadingBar,
} from "app/pages/.shared/LoadingBar/loadingBarActionCreators";

export const setProductsFilter = filterBy => dispatch => {
	dispatch(showLoadingBar());

	setTimeout(() => {
		dispatch(finishLoadingBar());
	}, 300);

	dispatch({
		type: SET_PRODUCTS_FILTER_BY,
		filterBy,
	});
};

export const unsetProductsFilter = name => {
	return {
		type: UNSET_PRODUCTS_FILTER_BY,
		name,
	};
};

export const unsetProductsFilters = filters => ({
	type: UNSET_PRODUCTS_FILTERS,
	filters,
});

export const setProductsFilters = filters => ({
	type: SET_PRODUCTS_FILTERS,
	filters,
});

export const replaceProductsFilters = filters => ({
	type: REPLACE_PRODUCTS_FILTERS,
	filters,
});

export const resetAllProductsFilter = () => {
	return {
		type: RESET_PRODUCTS_FILTER,
	};
};
