import React from "react";
import IconEngagementVoyageException from "app/pages/.shared/static/icons/IconEngagementVoyagesException";
import { FormattedMessage } from "react-intl";
import IconEngagementEconomies from "app/pages/.shared/static/icons/IconEngagementEconomies";
import IconEngagementExpertsEcoute from "app/pages/.shared/static/icons/IconEngagementExpertsEcoute";
import IconEngagementPaiementSecurise from "app/pages/.shared/static/icons/IconEngagementPaiementSecurise";
import IconCb from "app/pages/.shared/static/icons/IconCb";
import IconMastercard from "app/pages/.shared/static/icons/IconMastercard";
import IconVisa from "app/pages/.shared/static/icons/IconVisa";
import IconAmex from "app/pages/.shared/static/icons/IconAmex";
import IconPaiement4x from "app/pages/.shared/static/icons/IconPaiement4x";
import IconPaypal from "app/pages/.shared/static/icons/IconPaypal";
import PropTypes from "prop-types";
import "./Reassurance.scss";

const Reassurance = ({ showPaypal, showPayment4x }) => {
	return (
		<ul className="reassurance">
			<li className="reassurance__item">
				<IconEngagementVoyageException width={35} height={35} />
				<div className="reassurance__label">
					<FormattedMessage id="general.engagement.1" />
				</div>
			</li>
			<li className="reassurance__item">
				<IconEngagementEconomies width={35} height={35} />
				<div className="reassurance__label">
					<FormattedMessage id="general.engagement.2" />
				</div>
			</li>
			<li className="reassurance__item">
				<IconEngagementExpertsEcoute width={35} height={35} />
				<div className="reassurance__label">
					<FormattedMessage id="general.engagement.3" />
				</div>
			</li>
			<li className="reassurance__item">
				<IconEngagementPaiementSecurise width={35} height={35} />
				<div className="reassurance__label">
					<FormattedMessage id="general.engagement.4" />
				</div>
				<div className="reassurance__payment">
					<IconCb width={35} height={15} />
					<IconMastercard width={40} height={25} />
					<IconVisa width={40} height={15} />
					<IconAmex width={25} height={25} />
					{showPayment4x && <IconPaiement4x width={30} height={20} />}
					{showPaypal && <IconPaypal width={25} height={25} />}
				</div>
			</li>
		</ul>
	);
};

Reassurance.propTypes = {
	showPaypal: PropTypes.bool,
	showPayment4x: PropTypes.bool,
};

export default React.memo(Reassurance);
