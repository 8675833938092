import React from "react";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage } from "react-intl";
import DontLeave from "app/pages/.shared/DontLeave";
import RelativeLink from "app/pages/.shared/RelativeLink";
import "./TechnicalErrorDuplicateBooking.scss";
import { withRouter } from "react-router-dom";

const TechnicalErrorDuplicateBooking = ({
	startDate,
	endDate,
	bookingReference,
	email,
	isWhiteLabel,
	shop,
}) => {
	return (
		<div className="technical-error">
			<div className="technical-error__intro">
				<FormattedMessage
					id="technical.error.duplicate.booking.label"
					values={{
						startDate: (
							<FormattedDate
								value={new Date(startDate)}
								day="2-digit"
								month="2-digit"
								year="numeric"
							>
								{monthLabel => (
									<span className="technical-error__date">{monthLabel}</span>
								)}
							</FormattedDate>
						),
						endDate: (
							<FormattedDate
								value={new Date(endDate)}
								day="2-digit"
								month="2-digit"
								year="numeric"
							>
								{monthLabel => (
									<span className="technical-error__date">{monthLabel}</span>
								)}
							</FormattedDate>
						),
						bookingReference: (
							<span className="technical-error__reference-number">
								{bookingReference}
							</span>
						),
						email: <span className="technical-error__email">{email}</span>,
					}}
				/>
			</div>

			<DontLeave message={<FormattedMessage id="confirmation.contact.continue.headline" />}>
				{isWhiteLabel ? (
					<RelativeLink
						to={{ pathname: "/account/mybookings" }}
						design="primary"
						enableLoading
						data-cy={"go-to-my-bookings-button"}
					>
						<FormattedMessage id="technical.error.duplicate.booking.cta.label" />
					</RelativeLink>
				) : (
					<a
						className="relative-link relative-link--primary"
						href={`https://www.perfectstay.com/${shop}`}
						target="_blank"
						rel="noopener noreferrer"
						data-cy={"go-to-my-bookings-button"}
					>
						<FormattedMessage id="technical.error.duplicate.booking.cta.label" />
					</a>
				)}
			</DontLeave>
		</div>
	);
};

TechnicalErrorDuplicateBooking.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	bookingReference: PropTypes.string,
	email: PropTypes.string,
	shop: PropTypes.string,
	isWhiteLabel: PropTypes.bool,
};

export default withRouter(TechnicalErrorDuplicateBooking);
