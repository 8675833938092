import React from "react";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";
import { getFallbackRedirectUrl } from "src/@brand/appConstants";
import PropTypes from "prop-types";

export class BackTo extends React.PureComponent {
	render() {
		const { merchandisingCode, referrer, labelBack, labelFallback } = this.props;
		const fallbackRedirectUrl = getFallbackRedirectUrl();
		return (
			(referrer && (
				<BackButton
					to={{
						pathname: referrer,
						referrer,
					}}
					label={labelBack || ""}
				/>
			)) ||
			(merchandisingCode && (
				<BackButton
					to={{
						pathname: "/merch",
						search: `?code=${merchandisingCode}`,
						merchCode: merchandisingCode,
					}}
					label={labelBack || ""}
				/>
			)) || (
				<BackButton
					to={{
						pathname: fallbackRedirectUrl,
					}}
					label={labelFallback || ""}
				/>
			)
		);
	}
}

BackTo.propTypes = {
	merchandisingCode: PropTypes.string,
	referrer: PropTypes.string,
	labelBack: PropTypes.string,
	labelFallback: PropTypes.string,
};
