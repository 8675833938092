import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DontLeave from "app/pages/.shared/DontLeave";
import RelativeLink from "app/pages/.shared/RelativeLink";
import "./TechnicalErrorDefault.scss";
import { getFallbackRedirectUrl } from "src/@brand/appConstants";

// TODO Supprimer la cle technical.error.message
const TechnicalErrorDefault = ({ brandDisplayName }) => {
	return (
		<div className="technical-error">
			<div className="technical-error__intro">
				<FormattedMessage
					id="technical.error.default.message"
					values={{ brandName: brandDisplayName }}
				/>

				<DontLeave>
					<RelativeLink
						to={{ pathname: getFallbackRedirectUrl() }}
						design="primary"
						enableLoading
						data-cy={"go-to-fallback-url-button"}
					>
						<FormattedMessage id="confirmation.leave.button" />
					</RelativeLink>
				</DontLeave>
			</div>
		</div>
	);
};

TechnicalErrorDefault.propTypes = {
	brandDisplayName: PropTypes.string,
};

export default TechnicalErrorDefault;
