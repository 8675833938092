import "app/patchReact"; // redux-form s'appuie encore sur propTypes de React 15
import productsReducer from "app/pages/Listing/productsReducer";
import merchandisingReducer from "app/pages/Merchandising/merchandisingReducer";
import productsFilterReducer from "app/pages/Listing/ProductFilters/productsFilterReducer";
import ficheProduitReducer from "app/pages/FicheProduit/reducers/ficheProduitReducer";
import prePackageOfferReducer from "app/pages/PrePackage/reducers/prePackageOfferReducer";
import bookingReducer from "app/pages/Booking/bookingReducer";
import pushNotificationReducer from "app/pages/.shared/PushNotification/pushNotificationReducer";
import quotationReducer from "app/pages/Booking/Quotation/quotationReducer";
import profileReducer from "app/pages/Account/Profile/profileReducer";
import menuReducer from "app/pages/Header/menuReducer";
import cookiePolicyReducer from "app/pages/.shared/CookiePolicyFooter/cookiePolicyReducer";
import slideshowReducer from "app/pages/.shared/Slideshow/slideshowReducer";
import scrollSpyHeaderReducer from "app/pages/.shared/ScrollSpyHeader/scrollSpyHeaderReducer";
import responsiveReducer from "app/pages/.shared/responsive/responsiveReducer";
import emailReducer from "app/reducers/emailReducer";
import productsVisibilityReducer from "app/reducers/productsVisibilityReducer";
import partnerReducer from "app/reducers/partnerReducer";
import brandReducer from "app/reducers/brandReducer";
import themeReducer from "app/reducers/themeReducer";
import shopReducer from "app/reducers/shopReducer";
import myBookingReducer from "app/pages/Account/MyBookings/myBookingReducer";
import paymentReducer from "app/pages/Booking/Payment/paymentReducer";
import externalStatusReducer from "app/reducers/externalStatusReducer";
import marketingReducer from "app/reducers/marketingReducer";
import partnerDataReducer from "app/reducers/partnerDataReducer";
import { combineReducers } from "redux";
import { actionTypes as formActionTypes, reducer as formReducer } from "redux-form";
import {
	castToNumber,
	minZero,
	normalizeCreditCardNumber,
	normalizeCVC,
} from "app/utils/form/formNormalizor";
import {
	AUTH_SUCCESS,
	CHECK_AVAILABILITIES_REQUEST,
	CLEAR_BOOKING,
	FETCH_PRODUCTS_REQUEST,
} from "app/actionTypes";
import { getStore } from "app/configureStore";
import {
	getIncludedAccommodation,
	getIncludedFlight,
} from "app/pages/Booking/Quotation/quotationSelectors";
import authReducer from "app/pages/Auth/authReducer";
import envVarsReducer from "app/reducers/envVarsReducer";
import printReducer from "app/reducers/printReducer";
import sponsorShipReducer from "app/pages/Account/Parrainage/sponsorShipReducer";
import ioReducer from "app/reducers/ioReducer";
import productsFilterMetaDataReducer from "app/pages/Listing/ProductFilters/productsFilterMetaDataReducer";
import navigationReducer from "app/reducers/navigationReducer";
import documentsReducer from "app/reducers/documentsReducer";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { deleteUniquePassengersNameErrorsForm } from "./utils/utils";
import loadingBarReducer from "app/pages/.shared/LoadingBar/loadingBarReducer";
import navLogsReducer from "app/pages/Listing/NavLogs/navLogsReducer";
import abTestsReducer from "app/reducers/abTestsReducer";
import LandingAuthReducer from "app/pages/LandingPage/Auth/LandingAuthReducer";
import callCenterReducer from "app/reducers/callCenterReducer";

export default combineReducers({
	loadingBarStatus: loadingBarReducer,
	documents: documentsReducer,
	callCenter: callCenterReducer,
	merchandising: merchandisingReducer,
	products: productsReducer,
	productsFilters: productsFilterReducer,
	productsFilterMetaData: productsFilterMetaDataReducer,
	ficheProduit: ficheProduitReducer,
	quotation: quotationReducer,
	booking: bookingReducer,
	pushNotification: pushNotificationReducer,
	navigation: navigationReducer,
	form: formReducer
		.normalize({
			"booking-search-form": {
				adults: minZero,
				infants: minZero,
				children: minZero,
				duration: castToNumber,
				departureDate: castToNumber,
			},
			"booking-payment-form": {
				"payment.card.cvc": normalizeCVC,
				"payment.card.number": normalizeCreditCardNumber,
				"payment.cardBCMC.number": normalizeCreditCardNumber,
			},
			"my-bookings-payment-form": {
				"payment.card.cvc": normalizeCVC,
				"payment.card.number": normalizeCreditCardNumber,
			},
		})
		.plugin({
			"my-bookings-payment-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.INITIALIZE: {
						const paymentForm = Object.assign({}, state[action.key], {
							_error: undefined,
						});

						state[action.key] = paymentForm;

						return state;
					}
					case formActionTypes.CHANGE:
						if (action.form === "my-bookings-payment-form") {
							const paymentForm = Object.assign({}, state[action.key], {
								_error: undefined,
							});
							state[action.key] = paymentForm;
						}

						return state;
					default:
						return state;
				}
			},
			"resetPassword-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.INITIALIZE:
						return {
							...state,
							submitSucceeded: false,
							_error: undefined,
						};
					case formActionTypes.STOP_SUBMIT:
						return action.form === "resetPassword-form"
							? {
									...state,
									submitSucceeded: action.errors ? false : true,
							  }
							: state;
					case formActionTypes.CHANGE:
						return {
							...state,
							_error: undefined,
						};
					default:
						return state;
				}
			},
			"parrainage-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.INITIALIZE:
						return {
							...state,
							submitSucceeded: false,
							_error: undefined,
						};
					case formActionTypes.STOP_SUBMIT:
						return action.form === "parrainage-form"
							? {
									...state,
									submitSucceeded: action.errors ? false : true,
							  }
							: state;
					case formActionTypes.CHANGE:
						return {
							...state,
							submitSucceeded: undefined,
							_error: undefined,
						};
					default:
						return state;
				}
			},
			"recoverBookingData-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.INITIALIZE:
						return {
							...state,
							submitSucceeded: false,
							_error: undefined,
						};
					case formActionTypes.STOP_SUBMIT:
						return action.form === "recoverBookingData-form"
							? {
									...state,
									submitSucceeded: action.errors ? false : true,
							  }
							: state;
					case formActionTypes.CHANGE:
						return {
							...state,
							_error: undefined,
						};
					default:
						return state;
				}
			},
			"signup-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.CHANGE:
						return {
							...state,
							_error: undefined,
						};
					default:
						return state;
				}
			},
			"register-form": (state, action) => {
				switch (action.type) {
					case AUTH_SUCCESS:
						return {
							...state,
							submitSucceeded: true,
							_error: undefined,
						};
					case formActionTypes.INITIALIZE:
						return {
							...state,
							submitSucceeded: false,
							_error: undefined,
						};
					case formActionTypes.STOP_SUBMIT:
						return action.form === "register-form"
							? {
									...state,
									submitSucceeded: action.errors ? false : true,
							  }
							: state;
					case formActionTypes.CHANGE:
						return {
							...state,
							_error: undefined,
						};
					default:
						return state;
				}
			},
			"login-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.CHANGE:
						return {
							...state,
							_error: undefined,
						};
					default:
						return state;
				}
			},
			"changePassword-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.INITIALIZE:
						return {
							...state,
							submitSucceeded: false,
							_error: undefined,
						};
					case formActionTypes.STOP_SUBMIT:
						return action.form === "changePassword-form"
							? {
									...state,
									submitSucceeded: action.errors ? false : true,
							  }
							: state;
					case formActionTypes.CHANGE:
						return {
							...state,
							_error: undefined,
						};
					default:
						return state;
				}
			},
			"profile-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.INITIALIZE:
						return {
							...state,
							submitSucceeded: false,
							_error: undefined,
						};
					case formActionTypes.STOP_SUBMIT:
						return action.form === "profile-form"
							? {
									...state,
									submitSucceeded: action.errors ? false : true,
							  }
							: state;
					case formActionTypes.CHANGE:
						return {
							...state,
							_error: undefined,
						};
					default:
						return state;
				}
			},

			"unsubscribe-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.INITIALIZE:
						return {
							...state,
							submitSucceeded: false,
							_error: undefined,
						};
					case formActionTypes.STOP_SUBMIT:
						return action.form === "unsubscribe-form"
							? {
									...state,
									submitSucceeded: action.errors ? false : true,
							  }
							: state;
					case formActionTypes.CHANGE:
						return {
							...state,
							_error: undefined,
						};
					default:
						return state;
				}
			},

			"booking-search-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.CHANGE:
						return {
							...state,
							_error: undefined,
						};
					case CLEAR_BOOKING:
					case FETCH_PRODUCTS_REQUEST:
						return {
							...state,
							offer: {},
							departureDate: {},
							departureCityCode: {},
							duration: {},
							children: {
								initial: "0",
								value: "0",
							},
							infants: {
								initial: "0",
								value: "0",
							},
							adults: {
								initial: "2",
								value: "2",
							},
							childrenBirthdates: [],
							infantsBirthdates: [],
							rentalAccommodation: {
								initial: "",
								value: "",
							},
						};
					default:
						return state;
				}
			},
			"booking-quotation-form": (state, action) => {
				switch (action.type) {
					case formActionTypes.CHANGE:
						return {
							...state,
							_error: undefined,
						};
					case CLEAR_BOOKING:
					case CHECK_AVAILABILITIES_REQUEST:
						return {
							...state,
							accommodation: {
								value: getIncludedAccommodation(getStore().getState()),
							},
							flight: {
								value: getIncludedFlight(getStore().getState()),
							},
							board: {
								value: undefined,
							},
						};
					default:
						return state;
				}
			},
			"booking-payment-form": (state, action) => {
				switch (action.type) {
					// focus pour les inputs et change pour les radios
					case formActionTypes.FOCUS: {
						return set({
							...state,
							_error: undefined,
						});
					}
					case formActionTypes.CHANGE: {
						const duplicateState = deleteUniquePassengersNameErrorsForm(
							cloneDeep(state)
						);
						return {
							...duplicateState,
							_error: undefined,
						};
					}
					default:
						return state;
				}
			},
		}),
	prePackageOffer: prePackageOfferReducer,
	profile: profileReducer,
	auth: authReducer,
	menu: menuReducer,
	slideshow: slideshowReducer,
	isCookiePolicyAccepted: cookiePolicyReducer,
	scrollSpy: scrollSpyHeaderReducer,
	email: emailReducer,
	partner: partnerReducer,
	resolution: responsiveReducer,
	externalStatus: externalStatusReducer,
	payment: paymentReducer,
	shop: shopReducer,
	myBookings: myBookingReducer,
	partners: (a = {}) => a,
	seo: (a = {}) => a,
	brand: brandReducer,
	theme: themeReducer,
	productsVisibility: productsVisibilityReducer,
	marketing: marketingReducer,
	envVars: envVarsReducer,
	partnerData: partnerDataReducer,
	sponsorship: sponsorShipReducer,
	print: printReducer,
	io: ioReducer,
	navLogs: navLogsReducer,
	abTests: abTestsReducer,
	landingPagesAuth: LandingAuthReducer,
});
