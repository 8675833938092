import {
	BURN_CREDITS,
	CHECK_AVAILABILITIES_REQUEST,
	CLEAR_BOOKING,
	CLEAR_BURNED_CREDITS,
	CLEAR_PROMOTION_VALUE,
	FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_FAILURE,
	FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_SUCCESS,
	FETCH_FLIGHT_OPTIONS_REQUEST,
	FETCH_FLIGHT_OPTIONS_SUCCESS,
	FETCH_PRODUCTS_SUCCESS,
	PRE_BOOK_SUCCESS,
	SEND_PROMOTION_CODE_SUCCESS,
	UPDATE_BOOKING,
	UPDATE_BOOKING_ACTIVITY,
	UPDATE_BOOKING_ACTIVITY_DATE,
	UPDATE_BOOKING_CHILDREN_BIRTHDATES,
	UPDATE_BOOKING_INFANTS_BIRTHDATES,
	UPDATE_BOOKING_PASSENGER,
} from "app/actionTypes";
import {
	AB_TESTS,
	PASSENGER_TYPE,
	PAYMENT_METHODS,
	PAYMENT_OPTIONS,
	QUOTATION_PAYLOAD_PATHS,
} from "app/constants";

import {
	HYDRATE_QUOTATION_PAYLOAD,
	SAVE_QUOTATION_PAYLOAD,
} from "app/pages/Booking/bookingActionTypes";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";
import { getStore } from "app/configureStore";

export const INITIAL_BOOKING = {
	offer: {},
	departureCity: {},
	departureDate: undefined,
	endDate: undefined,
	price: undefined,
	publicPrice: undefined,
	adults: 2,
	children: 0,
	infants: 0,
	duration: {},
	transfer: {},
	accommodation: {},
	rentalAccommodation: {},
	board: {},
	credits: 0,
	creditIds: [],
	flight: {},
	luggage: 0,
	activities: [],
	promotionValue: 0,
	paymentType: "adyen_cse_card",
	insurance: {},
	contact: undefined,
	paymentMode: undefined, // TODO remettre la valeur a 1 apres l'ab test deposit
	passengers: [
		{
			type: PASSENGER_TYPE.ADULT,
			index: 1,
		},
		{
			type: PASSENGER_TYPE.ADULT,
			index: 2,
		},
	],
	quotationPayloads: [],
};

export default (booking = INITIAL_BOOKING, action) => {
	switch (action.type) {
		case HYDRATE_QUOTATION_PAYLOAD: {
			let hydratedBooking = booking.quotationPayloads
				? booking.quotationPayloads.find(
						quotationPayload => quotationPayload.productUri === action.productUri
				  )
				: {};
			QUOTATION_PAYLOAD_PATHS.forEach(path => {
				if (isEqual(get(booking, path), get(INITIAL_BOOKING, path))) {
					set(
						hydratedBooking,
						path,
						get(hydratedBooking, path, get(INITIAL_BOOKING.path))
					);
				} else {
					set(hydratedBooking, path, get(booking, path));
				}
			});
			return {
				...booking,
				...hydratedBooking,
			};
		}
		case SAVE_QUOTATION_PAYLOAD: {
			const localStorageQuotationPayloads = JSON.parse(
				localStorage.getItem(`reduxPersist:${action.shop}:booking`)
			);

			const quotationPayloads = get(localStorageQuotationPayloads, "quotationPayloads")
				? get(localStorageQuotationPayloads, "quotationPayloads").filter(
						quotationPayload => quotationPayload.productUri !== action.product.uri
				  )
				: [];

			const QUOTATION_PAYLOADS_MAX_LENGTH = 500;
			const quotationPayloadToSave = QUOTATION_PAYLOAD_PATHS.reduce(
				(acc, path) => set(acc, path, get(action.quotationpayload, path)),
				{
					productUri: action.product.uri,
					isChildrenBirthdateRequired: action.product.isChildrenBirthdateRequired,
				}
			);
			return {
				...booking,
				quotationPayloads: [
					...(quotationPayloads.length > QUOTATION_PAYLOADS_MAX_LENGTH
						? quotationPayloads.slice(1)
						: quotationPayloads),
					quotationPayloadToSave,
				],
			};
		}
		case FETCH_PRODUCTS_SUCCESS:
		case CLEAR_BOOKING:
			return { ...INITIAL_BOOKING, quotationPayloads: booking.quotationPayloads };
		case UPDATE_BOOKING_PASSENGER: {
			const passengers = booking.passengers.filter(passenger => {
				return passenger.type !== action.passengerType;
			});

			const matchedPassengers = booking.passengers.filter(passenger => {
				return passenger.type === action.passengerType;
			});

			for (let i = 0; i < action.count; i++) {
				passengers.push(
					Object.assign({}, matchedPassengers[i], {
						index: i + 1,
						type: action.passengerType,
					})
				);
			}

			const bookingPart = {
				passengers,
			};

			if (action.passengerType === PASSENGER_TYPE.ADULT) {
				bookingPart.adults = action.count;
			} else if (action.passengerType === PASSENGER_TYPE.CHILD) {
				bookingPart.children = action.count;
			} else if (action.passengerType === PASSENGER_TYPE.INFANT) {
				bookingPart.infants = action.count;
			}

			return Object.assign({}, booking, {
				...bookingPart,
			});
		}
		case UPDATE_BOOKING_CHILDREN_BIRTHDATES: {
			const passengers = booking.passengers.concat([]);
			const child = passengers.find(
				passenger =>
					passenger.type === PASSENGER_TYPE.CHILD && passenger.index === action.index + 1
			);

			child.birthdate = action.birthdate;

			return Object.assign({}, booking, {
				passengers,
			});
		}
		case UPDATE_BOOKING_INFANTS_BIRTHDATES: {
			const passengers = booking.passengers.concat([]);
			const infant = passengers.find(
				passenger =>
					passenger.type === PASSENGER_TYPE.INFANT && passenger.index === action.index + 1
			);

			infant.birthdate = action.birthdate;

			return Object.assign({}, booking, {
				passengers,
			});
		}
		case CHECK_AVAILABILITIES_REQUEST:
			// TODO composer les reducers : avoir un reducer pour booking quotation
			return Object.assign({}, booking, {
				accommodation: {},
				board: {},
				flight: {},
				insurance: {},
				paymentType: "adyen_cse_card",
				paymentMode: undefined, // TODO remettre la valeur a 1 apres l'ab test deposit
				promotionValue: 0,
				credits: 0,
				creditIds: [],
				transfer: {},
				activities: [],
			});
		case UPDATE_BOOKING:
			return Object.assign({}, booking, action.booking);
		case SEND_PROMOTION_CODE_SUCCESS:
			return Object.assign({}, booking, {
				promotionValue: action.res.data.value,
			});
		case BURN_CREDITS:
			return {
				...booking,
				credits: action.credits,
				creditIds: action.creditIds,
			};
		case CLEAR_BURNED_CREDITS:
			return {
				...booking,
				credits: 0,
				creditIds: [],
			};
		case CLEAR_PROMOTION_VALUE:
			return Object.assign({}, booking, { promotionValue: 0 });
		case FETCH_FLIGHT_OPTIONS_REQUEST: {
			// on reinitialise la sélection du transfer lorsque l'on recupère les transferts du vol selectionné
			return {
				...booking,
				transfer: undefined,
			};
		}
		case FETCH_FLIGHT_OPTIONS_SUCCESS: {
			const data = action.res.data;
			if (booking.flight.code === data.flight) {
				return {
					...booking,
					flight: {
						...booking.flight,
						flightOptions: {
							...booking.flight.flightOptions,
							...data.flightOptions,
						},
						transfers: data.transfers,
					},
				};
			}
			return booking;
		}
		case UPDATE_BOOKING_ACTIVITY: {
			const code = action.activity.code;
			const activityName = action.activity.name;
			const guestsCount = action.activity.guest.guestsCount;
			const guestCode = action.activity.guest.code;
			const guestPrice = action.activity.guest.price;

			const activities = booking.activities.concat([]);

			const activitytoUpdate = activities.find(activity => {
				return activity.code === code;
			});

			if (activitytoUpdate) {
				const guestToUpdate = activitytoUpdate.guests.find(guest => {
					return guest.code === guestCode;
				});

				if (guestToUpdate) {
					guestToUpdate.guestsCount = guestsCount;
				} else {
					activitytoUpdate.guests.push({
						code: guestCode,
						price: guestPrice,
						guestsCount,
					});
				}
			} else {
				activities.push({
					code: code,
					name: activityName,
					guests: [
						{
							code: guestCode,
							price: guestPrice,
							guestsCount,
						},
					],
				});
			}

			return { ...booking, activities };
		}

		case UPDATE_BOOKING_ACTIVITY_DATE: {
			const { code, date } = action.payload;

			const activities = [...booking.activities];

			const activitytoUpdate = activities.find(activity => {
				return activity.code === code;
			});

			if (activitytoUpdate) {
				activitytoUpdate.date = date;
			}
			return { ...booking, activities };
		}
		// TODO supprimer le case PRE_BOOK_SUCCESS après l'ab test Deposit
		case PRE_BOOK_SUCCESS: {
			const state = getStore().getState();
			const activeCampaigns = get(state, "abTests.activeCampaigns", []);
			const activeDepositCampaign = activeCampaigns.find(
				campaign => campaign.campaignID === AB_TESTS.DEPOSIT.CAMPAIGN_ID
			);

			if (!activeDepositCampaign) {
				return booking;
			}

			const paymentTypes = action.res.data.paymentTypes || [];

			const paymentDeposit = paymentTypes.find(
				paymentType =>
					paymentType.terms === PAYMENT_OPTIONS["2x"] &&
					paymentType.paymentType === PAYMENT_METHODS.CARD
			);

			if (!booking.paymentMode) {
				const activeVariation = activeDepositCampaign && activeDepositCampaign.variationID;

				let abTestPaymentMode =
					activeVariation === AB_TESTS.DEPOSIT.VARIATION_ID_DEPOSIT
						? PAYMENT_OPTIONS["2x"]
						: PAYMENT_OPTIONS["1x"];

				if (!paymentDeposit) {
					abTestPaymentMode = PAYMENT_OPTIONS["1x"];
				}

				return {
					...booking,
					paymentMode: abTestPaymentMode,
				};
			}

			return booking;
		}
		// TODO supprimer le case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_SUCCESS après l'ab test Deposit
		case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_SUCCESS: {
			const state = getStore().getState();
			const paymentTypes = get(state, "payment.paymentTypes", []);

			// si en récupérant la variation, on a pas la liste des moyens de payment, on selectionne par défaut le full payment
			// if (paymentTypes.length === 0) {
			// 	return {
			// 		...booking,
			// 		paymentMode: PAYMENT_OPTIONS["1x"],
			// 	};
			// }

			if (!booking.paymentMode && paymentTypes.length !== 0) {
				const paymentDeposit = paymentTypes.find(
					paymentType =>
						paymentType.terms === PAYMENT_OPTIONS["2x"] &&
						paymentType.paymentType === PAYMENT_METHODS.CARD
				);

				const activeCampaigns = action.activeCampaigns || [];
				const activeDepositCampaign = activeCampaigns.find(
					campaign => campaign.campaignID === AB_TESTS.DEPOSIT.CAMPAIGN_ID
				);

				const activeVariation = activeDepositCampaign && activeDepositCampaign.variationID;

				let abTestPaymentMode =
					activeVariation === AB_TESTS.DEPOSIT.VARIATION_ID_DEPOSIT
						? PAYMENT_OPTIONS["2x"]
						: PAYMENT_OPTIONS["1x"];

				if (activeVariation === AB_TESTS.DEPOSIT.VARIATION_ID_DEPOSIT && !paymentDeposit) {
					abTestPaymentMode = PAYMENT_OPTIONS["1x"];
				}

				if (!activeDepositCampaign) {
					abTestPaymentMode = PAYMENT_OPTIONS["1x"];
				}

				return {
					...booking,
					paymentMode: abTestPaymentMode,
				};
			}

			return booking;
		}
		case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_FAILURE: {
			// TODO supprimer le case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_FAILURE après l'ab test Deposit
			if (!booking.paymentMode) {
				return {
					...booking,
					paymentMode: PAYMENT_OPTIONS["1x"],
				};
			}

			return booking;
		}
		default:
			return booking;
	}
};
