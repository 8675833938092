import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import Helmet from "react-helmet";
import descriptionMessages from "./description";
import titleMessages from "./title";
import { getShop } from "app/utils/shop";
import { OFFER_PRICE_TYPES } from "app/constants";
import env from "app/utils/env";
import { getStore } from "app/configureStore";
import get from "lodash/get";

const titleByName = name => (formatMessage, data) => {
	const siteTitle = data.seo.siteTitle;
	return formatMessage(titleMessages[name], Object.assign({}, { siteTitle }, data));
};

const _title = {
	default: titleByName("home"),
	"search-booking": titleByName("search-booking"),
	"recover-booking-data": titleByName("recover-booking-data"),
	landing: titleByName("landing"),
	teasing: titleByName("teasing"),
	listing: titleByName("listing"),
	merch: titleByName("merch"),
	signup: titleByName("home"),
	login: titleByName("home"),
	resetpassword: titleByName("home"),
	changepassword: titleByName("home"),
	subscription: titleByName("subscription"),
	profile: titleByName("profile"),
	auth: titleByName("home"),
	product: titleByName("product"),
	help: titleByName("help"),
	experts: titleByName("experts"),
	break: titleByName("break"),
	"404": titleByName("404"),
};

const metaByName = name => (formatMessage, data) => {
	let descriptionName = name;

	// Dans le cas de la page produit, la description change en fonction du type de prix (A partir de X€, jusqu'à X% ou exclusif)
	if (name === "product" || name === "landing-product") {
		switch (data.pricingType) {
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_SAVE_UP_TO:
				descriptionName = "productSaveUpTo";
				break;
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM:
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM_WITH_FLIGHT:
				descriptionName = "productFrom";
				break;
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_EXCLUSIVE:
				descriptionName = "productExclusive";
				break;
			default:
				descriptionName = "productExclusive";
				break;
		}
	}

	const siteTitle = data ? data.seo.siteTitle : "";

	return [
		{
			property: "og:title",
			content: formatMessage(titleMessages[name], Object.assign({}, { siteTitle }, data)),
		},
		{
			name: "description",
			content: formatMessage(
				descriptionMessages[descriptionName],
				Object.assign({}, { siteTitle }, data)
			),
		},
		{
			property: "og:description",
			content: formatMessage(
				descriptionMessages[descriptionName],
				Object.assign({}, { siteTitle }, data)
			),
		},
	];
};

const META_PHOTO = {
	PS:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1518603373/brands/PS/og-ps.jpg",
	AF:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1528110031/brands/AF/og-af.jpg",
	VP:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1507798050/brands/VP/og-vp.jpg",
	SS:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1571749737/brands/SS/og-ss.jpg",
	EK:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1520344959/brands/EK/og-ek.jpg",
	LR:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1526566666/brands/LR/og-lr.jpg",
	HP:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1528896489/brands/HP/og-hp.jpg",
	VE:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1532674917/brands/VE/og-ve.jpg",
	DD:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1538400053/brands/DD/og-dd.jpg",
	MS:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1538400104/brands/MS/og-ms.jpg",
	VC:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1544775913/brands/VC/og-vc.jpg",
	TO:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1558687461/brands/TO/og-to.jpg",
	CD:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1553168038/brands/CD/og-cd.jpg",
	SE:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1552919316/brands/SE/og-se.jpg",
	LS:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1554738840/brands/LS/og-ls.jpg",
	UG:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1559854532/brands/UG/og-ug.jpg",
	GP:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1568843976/brands/GP/og-gp.jpg",
	PV:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1569855707/brands/PV/og-gp.jpg",
	TZ:
		"https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/v1579101612/brands/TZ/og-tz.jpg",
};

const BRAND_LABEL = {
	PS: "PerfectStay",
	AF: "La Collection Air France",
	VP: "Veepee by PerfectStay",
	SE: "Évasions Secrètes by PerfectStay",
	SS: "Corsair by PerfectStay",
	EK: "Emirates | The List by PerfectStay",
	LR: "La Redoute Voyages",
	HP: "Voyages Pirates by PerfectStay",
	VE: "Veepee by PerfectStay",
	DD: "DeinDeal by PerfectStay",
	VC: "VeryChic by PerfectStay",
	MS: "My-Store by PerfectStay",
	TO: "Transavia Smart Deals",
	CD: "Cdiscount Voyages by PerfectStay",
	LS: "Locasun by PerfectStay",
	UG: "Urlaubsguru by PerfectStay",
	GP: "Groupon by PerfectStay",
	PV: "Privalia by PerfectStay",
	TZ: "Travelzoo by PerfectStay",
};

const _meta = {
	default: (formatMessage, data) => {
		const brand = data.brand;
		const seo = data.seo;

		return [
			{
				name: "apple-mobile-web-app-title",
				content: BRAND_LABEL[brand],
			},
			{
				name: "language",
				content: getShop(),
			},
			{
				name: "robots",
				content: get(seo, "robots.default"),
			},
			{
				name: "googlebot",
				content: get(seo, "googlebot.default"),
			},
			{
				name: "google-site-verification",
				content: get(seo, "googleSiteVerificationId"),
			},
			{
				property: "og:type",
				content: "website",
			},
			{
				property: "og:image",
				content: META_PHOTO[brand],
			},
			{
				name: "thumbnail",
				content: META_PHOTO[brand],
			},
			{
				property: "fb:app_id",
				content: env("FACEBOOK_APP_ID"),
			},
		].concat(metaByName("home")(formatMessage, data));
	},
	merch: metaByName("merch"),
	listing: (formatMessage, data) => {
		const seo = data.seo;
		return [
			{
				name: "robots",
				content: get(seo, "robots.listing"),
			},
			{
				name: "googlebot",
				content: get(seo, "googlebot.listing"),
			},
		].concat(metaByName("listing")(formatMessage, data));
	},
	landing: () => {
		return [
			{
				name: "robots",
				content: "noindex, nofollow",
			},
			{
				name: "googlebot",
				content: "noindex, nofollow",
			},
		];
	},
	teasing: () => {
		return [
			{
				name: "robots",
				content: "noindex, nofollow",
			},
			{
				name: "googlebot",
				content: "noindex, nofollow",
			},
		];
	},
	subscription: metaByName("subscription"),
	profile: metaByName("profile"),
	login: () => {
		return [
			{
				name: "robots",
				content: "index, follow",
			},
			{
				name: "googlebot",
				content: "index, follow",
			},
		];
	},
	"search-booking": () => {
		return [
			{
				name: "robots",
				content: "index, follow",
			},
			{
				name: "googlebot",
				content: "index, follow",
			},
		];
	},
	"recover-booking-data": () => {
		return [
			{
				name: "robots",
				content: "noindex, nofollow",
			},
			{
				name: "googlebot",
				content: "noindex, nofollow",
			},
		];
	},
	signup: () => {
		return [
			{
				name: "robots",
				content: "index, follow",
			},
			{
				name: "googlebot",
				content: "index, follow",
			},
		];
	},
	product: (formatMessage, data) => {
		const seo = data.seo;
		return [
			{
				name: "robots",
				content: get(seo, "robots.product"),
			},
			{
				name: "googlebot",
				content: get(seo, "googlebot.product"),
			},
			{
				property: "og:image",
				content: data.photo,
			},
			{
				name: "thumbnail",
				content: data.photo,
			},
		].concat(metaByName("product")(formatMessage, data));
	},
	"landing-product": (formatMessage, data) => {
		return [
			{
				name: "robots",
				content: "noindex, nofollow",
			},
			{
				name: "googlebot",
				content: "noindex, nofollow",
			},
			{
				property: "og:image",
				content: data.photo,
			},
			{
				name: "thumbnail",
				content: data.photo,
			},
		].concat(metaByName("landing-product")(formatMessage, data));
	},
	help: metaByName("help"),
	experts: metaByName("experts"),
	"404": metaByName("404"),
};

const canonical = path => ({
	rel: "canonical",
	href: `${env("BASE_URL")}/${getShop()}${path}`,
});

const alternate = path => {
	const shops = getStore().getState().brand.shops;

	return Object.keys(shops).map(shop => {
		return {
			rel: "alternate",
			hreflang: shop === "fr-FR" ? shop.slice(0, 2) : shop,
			href: `${env("BASE_URL")}/${shop}${path}`,
		};
	});
};

const _link = {
	default: (formatMessage, { seo }) => [
		canonical("/home/login"),
		{
			rel: "icon",
			href: get(seo, "favicon"),
		},
	],
	listing: () => [canonical("/listing"), ...alternate("/listing")],
	teasing: () => [canonical("/teasing"), ...alternate("/teasing")],
	landing: () => [canonical("/lp"), ...alternate("/lp")],
	merch: () => [canonical("/merch"), ...alternate("/merch")],
	signup: () => [canonical("/home/login"), ...alternate("/home/signup")],
	login: () => [canonical("/home/login"), ...alternate("/home/login")],
	resetpassword: () => [canonical("/home/login"), ...alternate("/home/resetpassword")],
	changepassword: () => [canonical("/home/login"), ...alternate("/home/changepassword")],
	subscription: () => [canonical("/subscriptions"), ...alternate("/subscriptions")],
	profile: () => [canonical("/account/profile"), ...alternate("/account/profile")],
	auth: () => [canonical("/home/login"), ...alternate("/auth")],
	product: (formatMessage, { id }) => [canonical(`/${id}`), ...alternate(`/${id}`)],
	"landing-product": (formatMessage, { id }) => [
		canonical(`/product/${id}`),
		...alternate(`/product/${id}`),
	],
	help: () => [canonical("/help"), ...alternate("/help")],
	experts: () => [canonical("/experts"), ...alternate("/experts")],
	"search-booking": () => [canonical("/"), ...alternate("/")],
	"recover-booking-data": () => [canonical("/"), ...alternate("/")],
};

const pageGetter = (page, formatMessage, data) => {
	const _ret = {};
	if (typeof _title[page] === "function") {
		_ret.title = _title[page](formatMessage, data);
	}
	if (typeof _meta[page] === "function") {
		_ret.meta = _meta[page](formatMessage, data);
	}
	if (typeof _link[page] === "function") {
		_ret.link = _link[page](formatMessage, data);
	}
	return _ret;
};

const FONTS_DEFAULT = [
	{
		rel: "preload",
		as: "font",
		type: "font/woff2",
		href: "/static/font/718/sevenoneeight-book.woff2",
		crossorigin: true,
	},
	{
		rel: "preload",
		as: "font",
		type: "font/woff2",
		href: "/static/font/718/sevenoneeight-bold.woff2",
		crossorigin: true,
	},
	{
		rel: "preload",
		as: "font",
		type: "font/woff2",
		href: "/static/font/718/sevenoneeight-thin.woff2",
		crossorigin: true,
	},
	{
		rel: "preload",
		as: "font",
		type: "font/woff2",
		href: "/static/font/Marydale/Marydale-regular.woff2",
		crossorigin: true,
	},
];

const FONTS_TO_PRELOAD = {
	PS: FONTS_DEFAULT,
	AF: FONTS_DEFAULT,
	VP: [
		...FONTS_DEFAULT,
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/VPSansNext/VPSansNext-Regular.woff2",
			crossorigin: true,
		},
	],
	PV: [
		...FONTS_DEFAULT,
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/VPSansNext/VPSansNext-Regular.woff2",
			crossorigin: true,
		},
	],
	LS: [
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/Gotham/Gotham-Book.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/Gotham/Gotham-Bold.woff2",
			crossorigin: true,
		},
	],
	CD: [
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/Marydale/Marydale-regular.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/montserrat/montserrat-regular-webfont.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/montserrat/montserrat-bold-webfont.woff2",
			crossorigin: true,
		},
	],
	SE: FONTS_DEFAULT,
	TZ: [
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/cabin/cabin-regular-webfont.woff2",
			crossorigin: true,
		},
	],
	SS: FONTS_DEFAULT,
	EK: FONTS_DEFAULT,
	TO: [
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/nexa/Nexa_Regular-webfont.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/nexa/Nexa_XBold-webfont.woff2",
			crossorigin: true,
		},
	],
	VC: [
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/cabin/cabin-bold-webfont.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/cabin/cabin-regular-webfont.woff2",
			crossorigin: true,
		},
	],
	DD: [
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/opensans/open-sans-v15-latin-regular.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/opensans/open-sans-v15-latin-700.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/playfairdisplay/playfairdisplay-regular.woff2",
			crossorigin: true,
		},
	],
	MS: [
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/opensans/open-sans-v15-latin-regular.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/opensans/open-sans-v15-latin-700.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/playfairdisplay/playfairdisplay-regular.woff2",
			crossorigin: true,
		},
	],
	LR: [],
	UG: [
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/asap/asap-v9-latin-regular.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/opensans/open-sans-v15-latin-regular.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/opensans/open-sans-v15-latin-700.woff2",
			crossorigin: true,
		},
	],
	HP: [
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/bariol/bariol_regular-webfont.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/opensans/open-sans-v15-latin-regular.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/opensans/open-sans-v15-latin-700.woff2",
			crossorigin: true,
		},
	],
	VE: FONTS_DEFAULT,
	GP: [
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/opensans/open-sans-v15-latin-regular.woff2",
			crossorigin: true,
		},
		{
			rel: "preload",
			as: "font",
			type: "font/woff2",
			href: "/static/font/opensans/open-sans-v15-latin-700.woff2",
			crossorigin: true,
		},
	],
};

const HeadMetadata = ({
	intl: { formatMessage },
	data,
	page,
	seo,
	brand,
	prefetchRessources = [],
	...props
}) => {
	const completeData = Object.assign({}, data, { seo, brand });

	const head = pageGetter(page, formatMessage, completeData);

	head.link = head.link
		? head.link.concat(
				prefetchRessources.map(ressource => {
					return { rel: "preload", href: ressource, as: "image" };
				})
		  )
		: [];

	head.link = head.link.concat([
		{ rel: "preconnect", href: "https://res.cloudinary.com" },
		{ rel: "preconnect", href: "https://connect.facebook.net" },
		{ rel: "preconnect", href: "https://www.googletagmanager.com" },
		{ rel: "preconnect", href: "https://www.google-analytics.com" },
		{ rel: "preconnect", href: "https://x6pc9ierpi.execute-api.eu-west-1.amazonaws.com" },
		{ rel: "dns-prefetch", href: "https://res.cloudinary.com" },
		{ rel: "dns-prefetch", href: "https://connect.facebook.net" },
		{ rel: "dns-prefetch", href: "https://www.googletagmanager.com" },
		{ rel: "dns-prefetch", href: "https://www.google-analytics.com" },
		{ rel: "dns-prefetch", href: "https://x6pc9ierpi.execute-api.eu-west-1.amazonaws.com" },
	]);

	head.link = head.link.concat(FONTS_TO_PRELOAD[brand]);

	return <Helmet {...head} {...props} />;
};

HeadMetadata.propTypes = {
	intl: intlShape,
	data: PropTypes.object,
	seo: PropTypes.object,
	brand: PropTypes.string,
	page: PropTypes.string.isRequired,
	prefetchRessources: PropTypes.arrayOf(PropTypes.shape),
};

const mapStateToProps = state => {
	return {
		seo: state.seo,
		brand: state.brand.code,
	};
};

export default injectIntl(connect(mapStateToProps)(HeadMetadata));
