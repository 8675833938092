import { getStore } from "app/configureStore";
import env from "app/utils/env";

export const PRODUCT_EXPIRATION_DAY_LIMIT = 30;

export const IS_DEFAULT_LISTING_VIEW_LIST = false;

export const shouldGetProfileAtPayment = () => {
	const state = getStore().getState();
	return state.auth.token;
};

export const getProfileApiUrl = () => {
	return `${env("USER_AUTH_API_URL")}/me`;
};

export const getFallbackRedirectUrl = () => {
	return `/listing`;
};

export const buildPartnerTokenFromQuery = () => {
	return undefined;
};

export const SECOND_LABEL_BACKGROUND_COLOR = "white";
export const SECOND_LABEL_BORDER_COLOR = "black";
export const SECOND_LABEL_TEXT_COLOR = "black";

export const THIRD_LABEL_BACKGROUND_COLOR = "white";
export const THIRD_LABEL_BORDER_COLOR = "black";
export const THIRD_LABEL_TEXT_COLOR = "black";

export const FIRST_MERCHANDISING_POSITION = 0;
export const SECOND_MERCHANDISING_POSITION = 4;
export const THIRD_MERCHANDISING_POSITION = 8;
export const FOURTH_MERCHANDISING_POSITION = 10;

export const OAUTH_PARAMS = {
	CLIENT_ID: "ps_sso",
	RESPONSE_TYPE: "code",
	SCOPE: "CustomerFullInfo",
	IS_EXTERNAL: true,
};
