import React from "react";

export const registerStyle = () => WrappedComponent => {
	return class extends React.Component {
		constructor(props) {
			super(props);
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};
};
