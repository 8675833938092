import { AB_TESTS_STATUS } from "app/constants";

// TODO : remove file after ABTest

const meeroVariationsIDs = [
	"604193", // Page listing : production
	"604195", // Page product : production
];

export const meeroPhotosFilter = (photos = [], abTestsStatus, activeVariationID) => {
	const hasMeeroPhotos = photos.find(photo => photo.url.match(/_meero\.\w+$/));

	let filteredPhotos = photos;

	if (hasMeeroPhotos) {
		if (abTestsStatus === AB_TESTS_STATUS.LOADING || !abTestsStatus) {
			/* in order to remove videos (the only photos that are typed) */
			filteredPhotos = filteredPhotos.filter(photo => !photo.type);

			filteredPhotos = filteredPhotos.filter(photo => !photo.url.match(/_meero\.\w+$/));

			filteredPhotos = filteredPhotos.map(photo => {
				return {
					...photo,
					url: "" /* in order to only show the blurred thumbnail */,
				};
			});
		} else if (abTestsStatus === AB_TESTS_STATUS.SUCCESS) {
			const isMeeroVariation = meeroVariationsIDs.some(
				variation => variation === activeVariationID
			);

			if (isMeeroVariation) {
				filteredPhotos = photos.filter(photo => photo.url.match(/_meero\.\w+$/));
			} else {
				filteredPhotos = photos.filter(photo => !photo.url.match(/_meero\.\w+$/));
			}
		} else {
			filteredPhotos = photos.filter(photo => !photo.url.match(/_meero\.\w+$/));
		}
	}
	return filteredPhotos;
};
