import {
	AUTH_SUCCESS,
	AUTH_UPDATE_TOKEN,
	CHANGE_PASSWORD,
	FETCH_TOKEN_INFO_FAILURE,
	FETCH_TOKEN_INFO_SUCCESS,
	LOGOUT,
	LOGOUT_FROM_ALL_DEVICES_FAILURE,
	LOGOUT_FROM_ALL_DEVICES_REQUEST,
	LOGOUT_FROM_ALL_DEVICES_SUCCESS,
	LOGOUT_FROM_ONE_DEVICES_FAILURE,
	LOGOUT_FROM_ONE_DEVICES_SUCCESS,
	SET_ID,
	SET_TOKEN_FROM_PARTNER,
} from "app/actionTypes";
import Cookies from "js-cookie";
import axios from "axios";
import compact from "lodash/compact";
import { getCredentials, getTokenIdFromToken } from "app/utils/auth";
import { getStore } from "app/configureStore";
import { COOKIES_AUTH_EXPIRES, REGISTER_URL, SIGNUP_URL } from "app/constants";
import env from "app/utils/env";
import {
	sendTagOnEmailSignin,
	sendTagOnEmailSignup,
	sendTagOnEmailSignupError,
	sendTagOnError,
	sendTagOnLogout,
	sendTagOnSponsorshipSubscribeFailed,
	sendTagOnSponsorshipSubscribeSuccess,
	sendTagOnTokenDeleteError,
	sendTagOnTokenRenewByPassed,
	sendTagOnTokenRenewError,
	sendTagOnTokenRenewSuccess,
} from "app/utils/analytics";
import { parseQueries } from "app/utils/routerUtils";

import { validate } from "app/pages/Auth/Signup/SignupSchema";
import validationMessages from "app/utils/form/validationMessages.js";
import isEmpty from "lodash/isEmpty";
import getHistory from "app/configureHistory";
import get from "lodash/get";
import { updateEmail } from "app/reducers/emailActionCreators";
import { acceptCookyPolicy } from "app/pages/.shared/CookiePolicyFooter/cookiePolicyActionCreators";

const CHANGE_URL = "/auth/local/change";
const LOGIN_URL = "/auth/local/login";
const RESEND_URL = "/auth/local/resend";
const DELETE_TOKEN_URL = "/token/delete";
const INFO_TOKEN_URL = "/token/info";

export const changePassword = (payload, ownProps) => {
	// Dans le cas ou on est dans la page de profile, on aura pas de token dans la queyr
	// Dans le cas de la page de changePassword, le token se trouve dans la query

	const query = parseQueries(ownProps.location.search);

	const authorization = query.token || getCredentials().token || false;

	return {
		type: CHANGE_PASSWORD,
		promise: new Promise((resolve, reject) => {
			const token = getStore().getState().auth.token;
			const tokenId = getTokenIdFromToken(token);

			if (token && tokenId) {
				// suppression du tokenId, fire and forget
				axios
					.post(
						`${DELETE_TOKEN_URL}`,
						{ token: token, delete: [tokenId] },
						{
							baseURL: env("USER_AUTH_API_URL"),
							headers: {
								"Content-type": "text/plain",
							},
						}
					)
					.catch(error => {
						let message;

						if (error.response && error.response) {
							message = `${error.response.status} - ${JSON.stringify(
								error.response.data
							)}`;
						} else {
							message = JSON.stringify(error);
						}

						sendTagOnTokenDeleteError(message);
					});
			}

			axios
				.post(CHANGE_URL, payload, {
					baseURL: env("USER_AUTH_API_URL"),
					headers: {
						authorization,
						"Content-type": "application/json",
					},
				})
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		}),
	};
};

export const changePasswordSecurely = payload => {
	return {
		type: CHANGE_PASSWORD,
		promise: new Promise((resolve, reject) => {
			const token = getStore().getState().auth.token;
			const tokenId = getTokenIdFromToken(token);

			if (token && tokenId) {
				// suppression du tokenId, fire and forget
				axios
					.post(
						`${DELETE_TOKEN_URL}`,
						{ token: token, delete: [tokenId] },
						{
							baseURL: env("USER_AUTH_API_URL"),
							headers: {
								"Content-type": "text/plain",
							},
						}
					)
					.catch(error => {
						let message;

						if (error.response && error.response) {
							message = `${error.response.status} - ${JSON.stringify(
								error.response.data
							)}`;
						} else {
							message = JSON.stringify(error);
						}

						sendTagOnTokenDeleteError(message);
					});
			}

			axios
				.post(
					`${LOGIN_URL}?changePassword=true`,
					{
						credentials: {
							email: payload.email,
							password: payload.actualPassword,
						},
					},
					{
						baseURL: env("USER_AUTH_API_URL"),
						headers: {
							"Content-type": "text/plain",
						},
					}
				)
				.then(res => {
					axios
						.post(
							CHANGE_URL,
							{
								password: payload.password,
							},
							{
								baseURL: env("USER_AUTH_API_URL"),
								headers: {
									Authorization: res.data.token,
									"Content-type": "application/json",
								},
							}
						)
						.then(res => {
							resolve(res);
						});
				})
				.catch(err => {
					reject(err);
				});
		}),
	};
};

export const authSuccess = res => {
	const shop = getStore().getState().shop;

	try {
		Cookies.set("auth", "auth", { expires: COOKIES_AUTH_EXPIRES, path: `/${shop}` });
		Cookies.set("partner", res.data.partner.code, { path: `/${shop}` });

		// les test e2e cypress fails sans ce code. @see https://github.com/cypress-io/cypress/issues/2193
		if (window.Cypress) {
			Cookies.set("auth", "auth", {
				expires: COOKIES_AUTH_EXPIRES,
				path: `/${shop}`,
				domain: ".perfectstay.io",
			});
			Cookies.set("partner", res.data.partner.code, {
				path: `/${shop}`,
				domain: ".perfectstay.io",
			});
		}
	} catch (e) {
		// TODO
		console.error("cookies set", e); // eslint-disable-line no-console
	}
	return {
		type: AUTH_SUCCESS,
		uuid: res.data.uuid,
		token: res.data.token,
		email: res.data.email,
		partner: res.data.partner,
		partners: getStore().getState().partners,
	};
};

/**
 * Verifie si le token passé en parametre contient un tokenId. En renvoie un nouveau avec tokenId si ce n'est pas le cas
 * @param token
 * @param dispatch
 * @returns {Promise}
 */
export const getCredentialsWithTokenId = token => {
	return dispatch => {
		return new Promise(resolve => {
			const tokenId = getTokenIdFromToken(token);

			if (tokenId) {
				sendTagOnTokenRenewByPassed();
				resolve(token);
			} else if (token) {
				axios
					.post(
						"/token/renew",
						{ token: token },
						{
							baseURL: env("USER_AUTH_API_URL"),
							headers: {
								"Content-type": "text/plain",
							},
						}
					)
					.then(res => {
						const tokenWithTokenId = res.data.token;

						dispatch({
							type: AUTH_UPDATE_TOKEN,
							token: tokenWithTokenId,
						});

						sendTagOnTokenRenewSuccess();

						resolve(tokenWithTokenId);
					})
					.catch(error => {
						let message;

						if (error.response && error.response) {
							message = `${error.response.status} - ${JSON.stringify(
								error.response.data
							)}`;
						} else {
							message = JSON.stringify(error);
						}

						sendTagOnTokenRenewError(message);
					});
			}
		});
	};
};

export const getTokenInfo = () => {
	return (dispatch, getState) => {
		const token = getState().auth.token;

		return new Promise((resolve, reject) => {
			axios
				.post(
					`${INFO_TOKEN_URL}`,
					{ token: token },
					{
						baseURL: env("USER_AUTH_API_URL"),
						headers: {
							"Content-type": "text/plain",
						},
					}
				)
				.then(res => {
					const tokenInfos = res.data;

					dispatch({
						type: FETCH_TOKEN_INFO_SUCCESS,
						tokenInfos,
					});

					resolve(tokenInfos);
				})
				.catch(error => {
					dispatch({
						type: FETCH_TOKEN_INFO_FAILURE,
					});

					reject(error);
				});
		});
	};
};

export const logoutFromDevice = tokenId => {
	return (dispatch, getState) => {
		const token = getState().auth.token;
		return new Promise((resolve, reject) => {
			return axios
				.post(
					`${DELETE_TOKEN_URL}`,
					{ token: token, delete: [tokenId] },
					{
						baseURL: env("USER_AUTH_API_URL"),
						headers: {
							"Content-type": "text/plain",
						},
					}
				)
				.then(res => {
					const tokenInfos = res.data;
					const token = getState().auth.token;
					const actualTokenId = getTokenIdFromToken(token);

					dispatch({
						type: LOGOUT_FROM_ONE_DEVICES_SUCCESS,
						tokenInfos,
					});

					if (actualTokenId === tokenId) {
						const shop = getState().shop;
						try {
							Cookies.remove("auth", { path: `/${shop}` });
						} catch (e) {
							console.error("cookies set", e); // eslint-disable-line no-console
						} finally {
							dispatch({
								type: LOGOUT,
							});
						}
					}

					resolve(tokenInfos);
				})
				.catch(() => {
					dispatch({
						type: LOGOUT_FROM_ONE_DEVICES_FAILURE,
					});

					reject();
				});
		});
	};
};

export const logoutFromAllDevices = () => {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const tokenIds = compact(
				getState().auth.tokenInfos.map(tokenInfo => tokenInfo.tokenId)
			);
			const token = getState().auth.token;

			dispatch({
				type: LOGOUT_FROM_ALL_DEVICES_REQUEST,
			});

			return axios
				.post(
					`${DELETE_TOKEN_URL}`,
					{ token: token, delete: tokenIds },
					{
						baseURL: env("USER_AUTH_API_URL"),
						headers: {
							"Content-type": "text/plain",
						},
					}
				)
				.then(() => {
					const shop = getState().shop;

					try {
						Cookies.remove("auth", { path: `/${shop}` });
					} catch (e) {
						console.error("cookies set", e); // eslint-disable-line no-console
					} finally {
						dispatch({
							type: LOGOUT,
						});

						dispatch({
							type: LOGOUT_FROM_ALL_DEVICES_SUCCESS,
						});

						resolve();
					}
				})
				.catch(() => {
					dispatch({
						type: LOGOUT_FROM_ALL_DEVICES_FAILURE,
					});

					reject();
				});
		});
	};
};

export const logout = partnerCode => {
	return (dispatch, getState) => {
		const token = getState().auth.token;
		const shop = getState().shop;

		const tokenId = getTokenIdFromToken(token);

		sendTagOnLogout();

		if (token && tokenId) {
			// suppression du tokenId, fire and forget
			axios
				.post(
					`${DELETE_TOKEN_URL}`,
					{ token: token, delete: [tokenId] },
					{
						baseURL: env("USER_AUTH_API_URL"),
						headers: {
							"Content-type": "text/plain",
						},
					}
				)
				.catch(error => {
					let message;

					if (error.response && error.response) {
						message = `${error.response.status} - ${JSON.stringify(
							error.response.data
						)}`;
					} else {
						message = JSON.stringify(error);
					}

					sendTagOnTokenDeleteError(message);
				});
		}

		let partnerCodeAfterLogout = partnerCode;

		if (!partnerCode) {
			partnerCodeAfterLogout = getState().brand.defaultPartnerCode;
		}

		try {
			Cookies.remove("auth", { path: `/${shop}` });
			Cookies.set("partner", partnerCodeAfterLogout, { path: `/${shop}` });

			// les test e2e cypress fails sans ce code. @see https://github.com/cypress-io/cypress/issues/2193
			if (window.Cypress) {
				Cookies.remove("auth", { path: `/${shop}`, domain: ".perfectstay.io" });
				Cookies.set("partner", partnerCodeAfterLogout, {
					path: `/${shop}`,
					domain: ".perfectstay.io",
				});
			}
		} catch (e) {
			// TODO
			console.error("cookies set", e); // eslint-disable-line no-console
		} finally {
			dispatch({
				type: "SET_PARTNER",
				partner: partnerCodeAfterLogout,
				partners: getState().partners,
			});

			dispatch({
				type: LOGOUT,
			});
		}
	};
};

export const setTokenFromPartner = tokenFromPartner => {
	return {
		type: SET_TOKEN_FROM_PARTNER,
		tokenFromPartner,
	};
};

export const thunkSetId = uuid => {
	return (dispatch, getState) => {
		const actuelUuid = getState().auth.uuid;
		const shop = getState().shop;

		if (actuelUuid && actuelUuid !== uuid) {
			dispatch(logout());
		} else if (Cookies.get("auth", { path: `/${shop}` }) !== "auth") {
			try {
				Cookies.set("auth", "identified", {
					expires: COOKIES_AUTH_EXPIRES,
					path: `/${shop}`,
				});

				// les test e2e cypress fails sans ce code. @see https://github.com/cypress-io/cypress/issues/2193
				if (window.Cypress) {
					Cookies.set("auth", "identified", {
						expires: COOKIES_AUTH_EXPIRES,
						path: `/${shop}`,
						domain: ".perfectstay.io",
					});
				}
			} catch (e) {
				console.error("cookies set", e); // eslint-disable-line no-console
			}
		}

		dispatch({
			type: SET_ID,
			uuid,
		});
	};
};

export const signupPromise = ({
	data,
	partner,
	source,
	parentSponsorId,
	onAuthSuccess,
	dispatch,
}) => {
	return new Promise((resolve, reject) => {
		let _error;
		const errors = validate(data);
		if (!isEmpty(errors)) {
			_error = errors[Object.keys(errors)[0]];

			sendTagOnEmailSignupError({ status: "", error: _error.id });

			return reject({ _error });
		}

		return axios
			.post(
				SIGNUP_URL,
				{ credentials: data, partner, source },
				{
					baseURL: env("USER_AUTH_API_URL"),
					headers: { "Content-type": "text/plain" },
				}
			)
			.then(success => {
				dispatch(acceptCookyPolicy());

				return { success, isSignup: true };
			})
			.then(({ success }) => {
				if (parentSponsorId && partner.enableSponsorship) {
					return axios
						.post(
							env("SPONSORSHIP_API_URL") + "/subscribe",
							{
								sponsorId: parentSponsorId,
								sponsored: data.email,
							},
							{
								headers: {
									"Content-type": "application/json",
									Authorization: success.data.token,
								},
							}
						)
						.then(() => {
							sendTagOnSponsorshipSubscribeSuccess({
								sponsored: data.email,
								parentSponsorId: parentSponsorId,
							});
							return { success, isSignup: true };
						})
						.catch(err => {
							sendTagOnSponsorshipSubscribeFailed({
								sponsored: data.email,
								status: err.response.data.status || err.response.status,
							});
							return { success, isSignup: true };
						});
				}

				return { success, isSignup: true };
			})
			.catch(error => {
				if (error.response.status === 403) {
					return axios
						.post(
							LOGIN_URL,
							{ credentials: data },
							{
								baseURL: env("USER_AUTH_API_URL"),
								headers: { "Content-type": "text/plain" },
							}
						)
						.then(success => {
							dispatch(acceptCookyPolicy());
							return { success, isSignup: false };
						});
				}
				throw error;
			})
			.then(({ success, isSignup }) => {
				dispatch(authSuccess(success));

				if (onAuthSuccess && typeof onAuthSuccess === "function") {
					onAuthSuccess(success);
				} else {
					getHistory().replace("/listing"); // TODO pour le cas /home/signup continue de fonctionner. A refactorer en utilisant authSuccess
				}

				if (isSignup) {
					sendTagOnEmailSignup();
				} else {
					sendTagOnEmailSignin();
				}

				resolve();
			})
			.catch(error => {
				if (Object.prototype.hasOwnProperty.call(error, "_error")) {
					sendTagOnEmailSignupError({ status: "", error: error._error.id });
					sendTagOnError(error._error.id);
					reject(error);
				}

				let _error = validationMessages.generic;

				if (
					error.response &&
					(error.response.status === 403 || error.response.status === 401)
				) {
					_error = validationMessages.emailNotAvailable;
				}

				sendTagOnEmailSignupError({ status: error.response.status, error: _error.id });

				reject({ _error });
			});
	});
};

export const registerPromise = ({ data, partner, match, dispatch, shop, onAuthSuccess }) => {
	return new Promise((resolve, reject) => {
		let _error;
		const errors = validate(data);
		if (!isEmpty(errors)) {
			_error = errors[Object.keys(errors)[0]];
			return reject({ _error });
		}

		return axios
			.post(
				REGISTER_URL,

				{
					credentials: data,
					redirect: `${env("BASE_URL")}/${shop}/confirmRegistration`,
				},
				{
					baseURL: env("USER_AUTH_API_URL"),
					headers: { "Content-type": "text/plain" },
				}
			)
			.then(() => {
				dispatch(acceptCookyPolicy());
				dispatch(updateEmail(data.email));
				sendTagOnEmailSignup(); // on n'envoie le tag qu'une seule fois (lorsqu'il s'inscrit la première fois

				getHistory().push(`${match.url}/registration-succeeded`);
			})
			.catch(error => {
				if (error.response.status === 403) {
					/**
					 * présent base user (registered + confirmed)
					 * teasing : affichage date ouverture vente
					 * post-teasing:redirection listing
					 **/
					const today = new Date();
					const teasingEndDate = new Date(get(partner, "teasingEndDate"));
					if (today > teasingEndDate) {
						return axios
							.post(
								LOGIN_URL,
								{ credentials: data },
								{
									baseURL: env("USER_AUTH_API_URL"),
									headers: { "Content-type": "text/plain" },
								}
							)
							.then(res => {
								dispatch(acceptCookyPolicy());
								dispatch(authSuccess(res));

								if (onAuthSuccess && typeof onAuthSuccess === "function") {
									onAuthSuccess(res);
								}
								sendTagOnEmailSignin(); // on n'envoie le tag qu'une seule fois (lorsque les ventes seront ouvertes seulement)
								getHistory().replace(`/listing?uuid=${res.data.uuid}`);
							})
							.catch(error => {
								if (Object.prototype.hasOwnProperty.call(error, "_error")) {
									sendTagOnError(error._error.id);
									reject(error);
								}

								let _error = validationMessages.generic;

								if (
									error.response &&
									(error.response.status === 403 || error.response.status === 401)
								) {
									_error = validationMessages.emailNotAvailable;
								}

								sendTagOnError(_error.id);
								reject({ _error });
							});
					}

					getHistory().push(`${match.url}/registration-confirmed`);
				} else if (error.response.status === 409) {
					/**
					 * présent base optin (registered + not confirmed)
					 * reste sur la page mais affiche message avec renvoi de mail
					 */

					dispatch(updateEmail(data.email));

					getHistory().push(`${match.url}/registration-not-confirmed`);
				}
				throw error;
			});
	});
};

export const resendConfirmationMail = ({ email, match, shop }) => {
	return axios
		.post(
			RESEND_URL,
			{ email, redirect: `${env("BASE_URL")}/${shop}/confirmRegistration` },
			{
				baseURL: env("USER_AUTH_API_URL"),
				headers: { "Content-type": "text/plain" },
			}
		)
		.then(() => getHistory().push(`${match.url}/resend-confirmed`))
		.catch(err => {
			if (err.response.status === 400) {
				getHistory().push(`/technical-error/confirm-registration-failed`);
			} else if (err.response.status === 404 || err.response.status === 500) {
				getHistory().push(`${match.url}/user-not-found`);
			}
			throw err;
		});
};
