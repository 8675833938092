import { STORE_REFERRER } from "app/reducers/navigationActionTypes";

const INITIAL_STATE = { referrer: "" };

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case STORE_REFERRER: {
			return { ...state, referrer: action.referrer };
		}
		default: {
			return state;
		}
	}
};
