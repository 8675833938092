import {
	FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_REQUEST,
	FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_SUCCESS,
	FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_FAILURE,
} from "app/actionTypes";
import { AB_TESTS_STATUS } from "app/constants";

const INITIAL_STATE = {
	activeCampaigns: [],
	status: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_REQUEST: {
			return {
				activeCampaigns: [],
				status: AB_TESTS_STATUS.LOADING,
			};
		}
		case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_SUCCESS: {
			return {
				activeCampaigns: action.activeCampaigns,
				status: AB_TESTS_STATUS.SUCCESS,
			};
		}
		case FETCH_AB_TESTS_ACTIVE_CAMPAIGNS_FAILURE: {
			return {
				activeCampaigns: [],
				status: AB_TESTS_STATUS.FAILURE,
			};
		}
		default: {
			return state;
		}
	}
};
