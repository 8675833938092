import React from "react";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";

const PriceGuaranteeLink = () => (
	<RelativeLink
		rel="noopener noreferrer"
		target="_blank"
		to={{ pathname: "/priceMatchGuarantee" }}
	>
		<FormattedMessage id="menu.item.best.match.guarantee" />
	</RelativeLink>
);

export default PriceGuaranteeLink;
