import React from "react";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./aside-menu-account.scss";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import IconHelp from "app/pages/.shared/static/icons/IconHelp";
import IconMyBookings from "app/pages/.shared/static/icons/IconMyBookings";

class AsideMenuAccount extends React.PureComponent {
	constructor() {
		super();
		this.handleLogout = this.handleLogout.bind(this);
	}

	handleLogout() {
		this.props.onItemClick();
	}

	render() {
		const { onItemClick, helpContactUrl } = this.props;

		return (
			<section className="aside-menu-account">
				<div className="aside-menu-account__header">
					<div className="aside-menu-account__close" onClick={onItemClick}>
						<i className="icon icon--cross-white" />
					</div>
					<RelativeLink
						to={{ pathname: "/listing" }}
						className="aside-menu-account__logo"
						onClick={onItemClick}
					>
						<LogoBrandContainer isWhite={true} />
					</RelativeLink>
				</div>
				<div className="aside-menu-account__subheader">
					<BackButton to={{ pathname: "https://www.laredoute.fr/" }} label="La Redoute" />
				</div>
				<nav className="aside-menu-account__nav">
					<ul className="nav">
						<li className="nav__item nav__item--anchor nav__item--active">
							<RelativeLink to={{ pathname: "/listing" }} onClick={onItemClick}>
								<FormattedMessage id="menu.flash.sales.label" />
							</RelativeLink>
						</li>
						<li className="nav__item nav__item--anchor">
							<RelativeLink
								to={{ pathname: "/listing", hash: "#collection-permanente" }}
								onClick={onItemClick}
							>
								<FormattedMessage id="menu.collection.permanente.label" />
							</RelativeLink>
						</li>
						<li className="nav__item">
							<RelativeLink to={{ pathname: "/mybookings" }} onClick={onItemClick}>
								<IconMyBookings
									height={25}
									width={25}
									style={{ marginRight: "10px" }}
								/>
								<FormattedMessage id="menu.item.my.bookings" />
							</RelativeLink>
						</li>
						<li className="nav__item">
							<RelativeLink
								rel="noopener noreferrer"
								target="_blank"
								to={{ pathname: helpContactUrl }}
								onClick={onItemClick}
							>
								<IconHelp height={25} width={25} style={{ marginRight: "10px" }} />
								<FormattedMessage id="menu.item.help" />
							</RelativeLink>
						</li>
					</ul>
				</nav>
			</section>
		);
	}
}

AsideMenuAccount.propTypes = {
	helpContactUrl: PropTypes.string,
	onItemClick: PropTypes.func,
};

export default registerStyle(style)(AsideMenuAccount);
