import { connect } from "react-redux";
import { getSwitchableShops } from "app/reducers/shopSelector";

const ShopSwitcherContainer = props => {
	return typeof props.renderComponent === "function" && props.renderComponent(props);
};

const mapStateToProps = state => {
	return {
		currentShop: state.shop,
		baseUrl: state.envVars.BASE_URL,
		shops: getSwitchableShops(state),
	};
};

export default connect(mapStateToProps)(ShopSwitcherContainer);
