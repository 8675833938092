export const calculateEcheancierCasino = (total = 0, shouldApplyCasinoSansFrais = false) => {
	let taux = 0;

	if (total >= 50 && total <= 1000) {
		taux = 0.0238;
	} else if (total >= 1001 && total <= 1500) {
		taux = 0.0219;
	} else if (total >= 1500.01 && total <= 2000) {
		taux = 0.0198;
	} else if (total >= 2000.01 && total <= 3000) {
		taux = 0.017;
	} else if (total >= 3000.01 && total <= 6000) {
		taux = 0.0139;
	} else if (total >= 6000.01) {
		taux = 0.069;
	}

	if (shouldApplyCasinoSansFrais) {
		taux = 0;
	}

	const coutCredit = (total * taux).toFixed(2);

	const panierAvecFrais = total * (1 + taux);

	const mensualite = (Number(panierAvecFrais) / 4).toFixed(2);

	const mensualite1 = (Number(panierAvecFrais) - 3 * Number(mensualite)).toFixed(2);

	return {
		apport: Number(mensualite1),
		coutCredit: Number(coutCredit),
		mensualite: Number(mensualite),
	};
};
