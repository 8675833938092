import {
	addMinutes,
	isAfter,
	differenceInDays,
	differenceInHours,
	differenceInYears,
} from "date-fns";
import every from "lodash/every";
import intersection from "lodash/intersection";
import values from "lodash/values";
import isUndefined from "lodash/isUndefined";
import deburr from "lodash/deburr";
import parser from "ua-parser-js";
import { PRODUCTS_FILTERS_KEY, TRANSPORTATION_TYPES } from "app/constants";
import get from "lodash/get";
import compact from "lodash/compact";
import omit from "lodash/omit";
import validationMessages from "./form/validationMessages";

import set from "lodash/set";

export const isServerSide = typeof window === "undefined";

export const getGoogleMapsStaticUrlForCoords = ({ latitude, longitude, options = {} }) => {
	const { GOOGLE_API_KEY } = options;

	if (!GOOGLE_API_KEY) {
		return "";
	}

	const HEIGHT = Math.floor(options.height) || 500;
	const WIDTH = Math.floor(options.width) || 500;
	const zoom = options.zoom || 12;
	const base = "https://maps.googleapis.com/maps/api/staticmap";
	return `${base}${encodeURIComponent(
		`?center=${latitude},${longitude}&size=${WIDTH}x${HEIGHT}&key=${GOOGLE_API_KEY}&maptype=roadmap&zoom=${zoom}&markers=size:mid|${latitude},${longitude}`
	)}`;
};

export const containsTrainTransportationTypes = (transportationTypes = []) => {
	return transportationTypes.includes(TRANSPORTATION_TYPES.TRAIN);
};

export const isDeepUndefined = object => {
	if (isUndefined(object)) {
		return true;
	}
	return every(object, property => {
		let empty;

		if (typeof property === "object") {
			empty = isDeepUndefined(property);
		} else {
			empty = isUndefined(property);
		}

		return empty;
	});
};

/**
 * Converti par exemple "Les ventes flash" par "les-ventes-flash".
 * Supprime également les accents
 * @param url
 * @returns {string}
 */
export const normalizeFilterPath = url => deburr(url.replace(/ /g, "-").toLowerCase());

/**
 * retire tous les query qui ne sont pas utilisés pour les filtres listing et fusionne
 * les query des filtres issus de la query filterBy
 */
export const normalizeQueryFilters = query => {
	const filterKeys = intersection(values(PRODUCTS_FILTERS_KEY), Object.keys(query));

	const formattedFilters = `{${filterKeys.reduce(
		(stringAcc, filterKey, index) =>
			index < filterKeys.length - 1
				? stringAcc + `"${filterKey}":"${query[filterKey]}",`
				: stringAcc + `"${filterKey}":"${query[filterKey]}"`,
		""
	)}}`;

	const parsedFormattedFilters = JSON.parse(formattedFilters);

	return {
		...parsedFormattedFilters,
		...JSON.parse(query.filterBy || "{}"),
	};
};

/**
 * Calculate duration between 2 dates and return the day and hour part of the duration
 * @param {number} begin - begin date in ms
 * @param {number} end - begin date in ms
 * @returns {object} {day: 1, hour: 2}
 */
export function getDuration(begin, end) {
	if (end < begin) {
		return {
			day: 0,
			hour: 0,
		};
	}

	var ms = end - begin;
	var seconds = Math.floor(ms / 1000);
	var minutes = Math.floor(seconds / 60);
	var hours = Math.floor(minutes / 60);
	var days = Math.floor(hours / 24);
	var remainingHours = hours % 24;
	return {
		day: days,
		hour: remainingHours,
	};
}

/**
 * Calculate the total of durations
 * @param durations array of duration string ["hh:mm"]
 * @returns {*} total duration in "hh:mm"
 */
export const calculateStopDuration = (durations = []) => {
	if (durations.length === 0) {
		return undefined;
	}

	return durations.reduce((prevDuration, currentDuration) => {
		const prevDurationPart = prevDuration.split(":");
		const prevHour = prevDurationPart[0];
		const prevMinutes = prevDurationPart[1];

		const currentDurationPart = currentDuration.split(":");
		const currentHour = currentDurationPart[0];
		const currentMinutes = currentDurationPart[1];

		let minuteDuration = Number(prevMinutes) + Number(currentMinutes);
		const hourFromMinutes = Math.floor(minuteDuration / 60);
		minuteDuration = minuteDuration - hourFromMinutes * 60;

		const hourDuration = Number(prevHour) + Number(currentHour) + hourFromMinutes;

		minuteDuration = minuteDuration > 9 ? minuteDuration : "0" + minuteDuration;

		return `${hourDuration}:${minuteDuration}`;
	});
};

/**
 * Get number of milliseconds of duration
 * @param duration duration string ["hh:mm"]
 */
export const getDurationAsMilliseconds = duration => {
	if (!duration) {
		return undefined;
	}
	const durationPart = duration.split(":");
	const hours = durationPart[0];
	const minutes = durationPart[1];

	return Number(hours) * 60 * 60 * 1000 + Number(minutes) * 60 * 1000;
};

/**
 * retourne le nombre de jours dans un mois
 * @param month 1 pour janvier
 */
export const getDaysInMonth = month => {
	// 0 est le dernier jour du mois précédent
	// on choisit 1972 pour etre sur de retourner 29 pour février
	return new Date(1972, month, 0).getDate();
};

const daysInMonth = (m, y) => {
	switch (m) {
		case 1:
			return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
		case 8:
		case 3:
		case 5:
		case 10:
			return 30;
		default:
			return 31;
	}
};

/**
 * @see https://gomakethings.com/how-to-check-if-a-date-is-valid-with-vanilla-javascript/
 * @param year
 * @param month 1 pour janvier
 * @param day
 * @returns {boolean}
 */
export const checkDateValidity = (year, month, day) => {
	const monthJavascript = parseInt(month, 10) - 1;
	return (
		monthJavascript >= 0 &&
		monthJavascript < 12 &&
		day > 0 &&
		day <= daysInMonth(monthJavascript, year)
	);
};

/**
 * Return days count between 2 dates
 * @param start instance of Date or "DD-MM-YYYY"
 * @param end instance of Date or "DD-MM-YYYY"
 * @returns {number|*}
 */
export const getDayCount = (start, end) => {
	return differenceInDays(new Date(end), new Date(start));
};

/**
 * Return hours count between 2 dates
 * @param start instance of Date or "DD-MM-YYYY"
 * @param end instance of Date or "DD-MM-YYYY"
 * @returns {number|*}
 */
export const getHoursCount = (start, end) => {
	return differenceInHours(new Date(end), new Date(start));
};

/**
 * Get duration ["hh:mm"] from milliseconds
 * @param duration duration string ["hh:mm"]
 */
export const getMillisecondsAsDuration = milliseconds => {
	if (!milliseconds || milliseconds === 0) {
		return "00:00";
	}

	let minutes = milliseconds / 60 / 1000;

	let hourDuration = Math.floor(minutes / 60);

	let minuteDuration = minutes - hourDuration * 60;

	minuteDuration = minuteDuration > 9 ? minuteDuration : "0" + minuteDuration;
	hourDuration = hourDuration > 9 ? hourDuration : "0" + hourDuration;
	return `${hourDuration}:${minuteDuration}`;
};

/**
 * Check if a timestamp + minutes < now
 * @param timestamp
 * @param minutes number of minutes
 * @returns {boolean} true is timestamp + minutes < now , false otherwise
 */
export const isExpiredAfter = (timestamp, minutes) => {
	const today = new Date();
	const date = new Date(timestamp);
	const limiteDate = addMinutes(date, minutes);
	return isAfter(today, limiteDate);
};

export const calculateAgeFromBirthday = birthDate => {
	return differenceInYears(new Date(), new Date(birthDate));
};

export const hexToRgbA = (hex, opacity) => {
	let c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + opacity + ")";
	}
	return "rgba(0,0,0,1)";
};

export const getTwoDigitsDate = date => (date < 10 ? "0" : "") + date;

/**
 * Retourne une Date UTC pour analytics au format YYYY-MM-DD
 * @param timestamp
 * @returns {string}
 */
export const getUTCDateForAnalytics = timestamp => {
	const utcDate = new Date(timestamp);
	const utcMonth =
		utcDate.getUTCMonth() + 1 >= 10
			? utcDate.getUTCMonth() + 1
			: "0" + (utcDate.getUTCMonth() + 1);
	const utcDay = utcDate.getUTCDate() >= 10 ? utcDate.getUTCDate() : "0" + utcDate.getUTCDate();
	return `${utcDate.getUTCFullYear()}-${utcMonth}-${utcDay}`;
};

/**
 * retourne le userAgent et le type de device
 * @returns {*}
 */
export const getDeviceInfo = () => {
	const userAgent = navigator.userAgent;

	if (userAgent) {
		const uaParser = parser(userAgent);
		const deviceType = uaParser.device.type || "desktop";

		return {
			userAgent,
			deviceType,
		};
	}

	return {};
};

export const deleteUniquePassengersNameErrorsForm = state => {
	if (state._active) {
		const [passenger, indexPassenger, fieldPassenger] =
			state._active.match(/.*passengers\[(.*)\]\.name\.(.*Name)/) || [];
		let passengerNameState;

		if (passenger && indexPassenger && fieldPassenger) {
			const fieldToChange =
				fieldPassenger === "lastName"
					? {
							firstName:
								get(
									state,
									`${state._active.match(/(.*name)/)[0]}.firstName.submitError.id`
								) === validationMessages.uniqueNamePassengers.id
									? {
											...omit(
												get(state, state._active.match(/(.*name)/)[0])
													.firstName,
												"submitError"
											),
									  }
									: get(state, state._active.match(/(.*name)/)[0]).firstName,
					  }
					: {
							lastName:
								get(
									state,
									`${state._active.match(/(.*name)/)[0]}.lastName.submitError.id`
								) === validationMessages.uniqueNamePassengers.id
									? {
											...omit(
												get(state, state._active.match(/(.*name)/)[0])
													.lastName,
												"submitError"
											),
									  }
									: get(state, state._active.match(/(.*name)/)[0]).lastName,
					  };
			passengerNameState = {
				...get(state, state._active.match(/(.*name)/)[0]),
				...fieldToChange,
			};
			set(state, state._active.match(/(.*name)/)[0], passengerNameState);
		}
	}
	return state;
};
export const getRouteComponentParts = route => {
	const components = route.getComponent();
	const Header = components.header;
	const Main = components.main;
	const Footer = components.footer;
	const Aside = components.aside;
	let childRoutes = [];
	if (typeof route.getChildRoutes === "function") {
		childRoutes = route.getChildRoutes();
	}

	return {
		Aside,
		Header,
		Main,
		Footer,
		childRoutes,
		onEnter: route.onEnter,
	};
};

/**
 * convertit la valeur en un montant valide
 * 32 => 32
 * 32.4 => 32.4
 * "32" => 32
 * "32.3" => 32.3
 * "32,3" => 32.3
 * @param amount
 * @returns {number|undefined}
 */
export const parseAmount = amount => {
	let amountToParse = amount;

	if (amount && typeof amount === "string") {
		amountToParse = amount.replace(",", ".");
	}

	if ((amountToParse !== 0 && !amountToParse) || (amountToParse && isNaN(amountToParse))) {
		return undefined;
	}

	return parseFloat(amountToParse);
};

/**
 * Retourne l'url complète avec la shop.
 * Si on a qu'une seule shop pour le domaine, alors on retourne l'url avec cette shop
 * Si on a plusieurs shop pour le domaine, alors on retourne l'url avec la shop par défaut
 * Si cette url avec la shop par défault, n'existe pas, alors on retourne l'url avec la première shop (ne devrait pas arriver)
 */
export const getDomainWithShop = (domainsByShop = {}, actualDomain, defaultShop) => {
	const shops = Object.keys(domainsByShop);

	const domains = compact(
		shops.map(shop => {
			return domainsByShop[shop] === actualDomain
				? { shop, domain: domainsByShop[shop] }
				: undefined;
		})
	);

	if (domains.length === 1) {
		return `${domains[0].domain}/${domains[0].shop}`;
	}

	const domain = domains.find(domain => domain.shop === defaultShop);

	if (domain) {
		return `${domain.domain}/${domain.shop}`;
	}

	return `${domains[0].domain}/${domains[0].shop}`;
};

export const injectScript = htmlPayload => {
	const s = document.createElement("script");
	s.type = "text/javascript";
	document.getElementsByTagName("head")[0].appendChild(s);
	s.innerText = htmlPayload;
};
